import axios from 'axios';
import { BASE_URL } from '../components/Constant';

//once admin upload all the assets file
function createAssets(formData) {
  const endpoint = BASE_URL + 'asset/createAssets';

  return axios.post(endpoint, formData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data'
    }
  });
}

//once admin upload all the assets file
function uploadAssetImages(formData) {
  const endpoint = BASE_URL + 'asset/uploadAssetImages';

  return axios.post(endpoint, formData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data'
    }
  });
}

//get all the assets list for admin
function getAssets() {
  const endpoint = BASE_URL + 'asset/getAssets';

  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//get all the assets list for admin
function getEmployeeAssets() {
  // const endpoint = BASE_URL + 'asset/getEmployeeAssets';

  // eslint-disable-next-line no-undef
  const employeeDisplayName = localStorage.getItem('name');

  const endpoint = BASE_URL + `asset/getEmployeeAssets/${employeeDisplayName}`;

  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//update asset by admin
function updateAssetAndImage(formData) {
  const endpoint = BASE_URL + 'asset/updateAssetAndImage';

  return axios.put(endpoint, formData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data'
    }
  });
}

//once admin add 'new asset' with the image file
function createAsset(formData) {
  const endpoint = BASE_URL + 'asset/createAsset';

  return axios.post(endpoint, formData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data'
    }
  });
}

//once admin add 'new asset' with the image file
function updateAssetOperator(assetData) {
  const endpoint = BASE_URL + 'asset/updateAssetOperator';

  return axios.put(endpoint, assetData, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//once admin add 'new asset' with the image file
function deleteAsset(assetId) {
  const endpoint = BASE_URL + `asset/deleteAsset/${assetId}`;

  return axios.delete(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//query asset from MyAsset page
function findAssets(queryValue) {
  const endpoint = BASE_URL + `asset/findAssets/${queryValue}`;

  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//fetch asset with the tokenMac/ that match with bluetoothTool mac
function getAssetWithTokenMac() {
  const endpoint = BASE_URL + 'asset/getAssetWithTokenMac';

  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//update asset tokenMac
function updateAssetTokenMac(assetPayload) {
  const endpoint = BASE_URL + 'asset/updateAssetTokenMac';

  return axios.put(
    endpoint,
    { assetPayload: assetPayload },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

// get asset logs
function getAssetLogs(assetId) {
  const endpoint = BASE_URL + `asset/getAssetLogs/${assetId}`;

  return axios.get(endpoint, {
    headers: {
      // eslint-disable-next-line no-undef
      authorization: `${localStorage.getItem('token')}`
    }
  });
}

//update assetId
function updateAssetId(assetPayload) {
  const endpoint = BASE_URL + 'asset/updateAssetId';

  return axios.put(
    endpoint,
    { assetPayload: assetPayload },
    {
      headers: {
        // eslint-disable-next-line no-undef
        authorization: `${localStorage.getItem('token')}`
      }
    }
  );
}

const assetService = {
  createAssets,
  getAssets,
  uploadAssetImages,
  getEmployeeAssets,
  updateAssetAndImage,
  createAsset,
  updateAssetOperator,
  deleteAsset,
  findAssets,
  getAssetWithTokenMac,
  updateAssetTokenMac,
  getAssetLogs,
  updateAssetId
};

export default assetService;
