import React, { useEffect, useState } from 'react';
import { Button, Grid, InputLabel, makeStyles, Paper, Typography, withStyles } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import ReactSelect from 'react-select';
import { assetIdSchema, calculateAssetIds, isNewAssetIdValid } from '../../table/hepler';
import PropTypes from 'prop-types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import assetService from '../../../../services/AssetService';
import toast from 'react-hot-toast';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      // marginTop: theme.spacing(1)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: 'rgb(107, 108, 114)',
    fontSize: '1rem'
  },
  bootstrapInput: {
    maxWidth: '350px',
    width: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  }
}));

const AssetIdChange = ({ row, assetRecords, handleAssetClick, setRows, assetLoading }) => {
  const classes = useStyles();
  const [missingAssetIdOptions, setMissingAssetIdOptions] = useState([]);
  const [createNewClicked, setCreateNewClicked] = useState(false);
  const [createNewAssetId, setCreateNewAssetId] = useState(null);

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(assetIdSchema)
  });

  useEffect(() => {
    const { missingAssetIds } = calculateAssetIds(assetRecords);
    const missingIdOptions = missingAssetIds.map((id) => ({
      label: id,
      value: id
    }));

    setMissingAssetIdOptions(missingIdOptions);
  }, [assetRecords]);

  // Handle toggling between choosing and creating new Asset ID
  const handleAssetSelection = (isCreateNew) => {
    if (isCreateNew) {
      const { nextAssetId } = calculateAssetIds(assetRecords);
      setCreateNewClicked(true);
      setCreateNewAssetId(nextAssetId);
      setValue('newAssetId', nextAssetId); // Set newAssetId in form
      setValue('newAssetIdFromMissingIds', null); // Clear assetId in form
    } else {
      setCreateNewClicked(false);
      setCreateNewAssetId(null);
      setValue('newAssetId', null); // Clear newAssetId in form
    }
  };

  const onSubmit = (data) => {
    //check newAssetId if created, must be higher than the last assetId
    const { nextAssetId } = calculateAssetIds(assetRecords);
    const validNewAssetId = data?.newAssetId && isNewAssetIdValid(data.newAssetId, nextAssetId);
    if (!validNewAssetId) {
      return toast.error(`AssetId must be higher than ${nextAssetId - 1} `);
    }

    const assetPayload = {
      id: row?.id,
      previousAssetId: row?.assetId,
      changeAssetId: data?.newAssetIdFromMissingIds || data?.newAssetId,
      employeeDisplayName: row?.operator
    };
    // eslint-disable-next-line no-console, no-undef
    console.log('assetPayload', assetPayload);

    assetService
      .updateAssetId(assetPayload)
      .then(() => {
        const updatedRows = assetRecords.map((asset) => {
          if (asset.id === row.id) {
            return { ...asset, assetId: assetPayload?.changeAssetId };
          }
          return asset;
        });

        setRows(updatedRows);
        toast.success('Successfully changed assetId');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console, no-undef
        console.log('error is:', error);
        return toast.error(error?.response?.data?.message || 'Asset id could not match');
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {/* choose from missing asset ids */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <InputLabel shrink htmlFor="AssetId" className={classes.inputLabel}>
              AssetId
            </InputLabel>
            {!assetLoading ? (
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} sm={4}>
                  <span>Choose from missing asset Ids </span>
                  <Controller
                    name="newAssetIdFromMissingIds"
                    control={control}
                    render={({ field }) => (
                      <>
                        <ReactSelect
                          className="basic-single"
                          classNamePrefix="select"
                          value={missingAssetIdOptions?.find((option) => option.value === field.value) || null}
                          isSearchable={true}
                          name="newAssetIdFromMissingIds"
                          options={missingAssetIdOptions}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption ? selectedOption?.value : null);
                            handleAssetSelection(false);
                          }}
                        />
                      </>
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={1}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Typography>OR</Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  {!createNewClicked ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleAssetSelection(true)}
                      style={{ marginTop: '15px' }}
                    >
                      Create New
                    </Button>
                  ) : (
                    <>
                      <span>
                        New asset must be equal or higher than <b style={{ color: 'black' }}>{createNewAssetId}</b>{' '}
                      </span>
                      <Controller
                        name="newAssetId"
                        control={control}
                        defaultValue={createNewAssetId}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <BootstrapInput
                              {...field}
                              onChange={(e) => {
                                const value = e.target.value;
                                field.onChange(value);

                                // Validation logic: Ensure value is equal or higher than createNewAssetId
                                if (Number(value) < Number(createNewAssetId)) {
                                  setError('newAssetId', {
                                    type: 'manual',
                                    message: `New asset ID must be equal to or greater than ${createNewAssetId}`
                                  });
                                } else {
                                  clearErrors('newAssetId');
                                }
                              }}
                              className={`${classes.bootstrapInput} ${error ? 'error-class' : ''}`} // Add error styling if needed
                            />
                            {error && (
                              <p
                                style={{
                                  color: 'red',
                                  marginTop: '5px',
                                  fontSize: '0.8rem'
                                }}
                              >
                                {error.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </>
                  )}
                </Grid>
                {errors?.assetId?.newAssetId?.message && (
                  <p
                    style={{
                      color: 'red',
                      marginTop: '10px',
                      fontSize: '0.8rem',
                      lineHeight: '1',
                      marginBottom: '0px',
                      paddingBottom: '0px'
                    }}
                  >
                    {errors?.assetId?.newAssetId?.message}
                  </p>
                )}
                <Grid
                  item
                  xs={12}
                  sm={3}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}
                >
                  <Button
                    onClick={handleAssetClick}
                    variant="outlined"
                    color="secondary"
                    style={{ marginRight: '5px' }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" variant="outlined" color="primary">
                    Save
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <div>Loading...</div>
            )}
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};

export default AssetIdChange;

AssetIdChange.propTypes = {
  row: PropTypes.any,
  assetRecords: PropTypes.any,
  onChange: PropTypes.func,
  handleAssetClick: PropTypes.func,
  setRows: PropTypes.func,
  assetLoading: PropTypes.bool
};
