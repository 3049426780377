import { z } from 'zod';

export function formattedAssets(assetRecords) {
  return assetRecords.map((item) => ({
    id: item._id,
    assetId: item.assetId || '',
    tokenMac: item.tokenMac || '',
    make: item.make || '',
    model: item.model || '',
    description: item.description || '',
    tradeSpecific: item.tradeSpecific || '',
    supplier: item.supplier || '',
    category: item.category || '',
    dateOfPurchase: item.dateOfPurchase || '',
    purchasePrice: Number(item.purchasePrice) || '',
    owner: item.owner || '',
    operator: item.operator || '',
    location: item.beaconLocation || item.location || '',
    locationDate: item.locationDate || '',
    insuranceName: item.insuranceName || '',
    insurancePolicy: item.insurancePolicy || '',
    insuranceExpiry: item.insuranceExpiry || '',
    maintenanceSchedule: item.maintenanceSchedule || '',
    disposalDate: item.disposalDate || '',
    image: item.image || '',
    comments: item.comments || '',
    activeStatus: item.activeStatus || false,
    fixedLocation: item.fixedLocation || false,
    maps: item.position || null,
    beaconTimestamp: item.beaconTimestamp || null,
    beaconTokenMac: item.beaconTokenMac || false,
    trackerTokenMac: item.trackerTokenMac || false,
    mapIcon: item.mapIcon || null,
    linkedAsset: item.linkedAsset || false,
    linkedAssetId: item.linkedAssetId || ''
  }));
}

export function isChangedAssetImageValid(assetId, file) {
  const fileName = file.name.split('.')[0];

  if (assetId === fileName) {
    return true;
  }
  return false;
}

export function createOptions(rows, optionName) {
  // eslint-disable-next-line no-undef
  const uniqueOptions = new Set(rows.map((r) => r[optionName]).filter((option) => option && option.trim() !== ''));
  const options = Array.from(uniqueOptions).map((option) => {
    return { value: option, label: option };
  });
  return options;
}

export function isCommentValid(activeStatus, comment) {
  if (activeStatus === false && (comment === null || comment.trim() === '')) {
    return false;
  } else {
    return true;
  }
}

const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];
const maxFileSize = 10 * 1024 * 1024;

// const imageSchema = z.object({
//   name: z.string(),
//   size: z.number().max(maxFileSize, { message: 'File size should not exceed 3MB' }),
//   type: z.string().refine((type) => ACCEPTED_IMAGE_TYPES.includes(type), {
//     message: 'Invalid image type. Accepted types are .jpeg, .jpg, .png, .webp'
//   })
// });

const imageSchema = z
  .any()
  .refine((file) => file?.size <= maxFileSize, `Max image size is 3MB.`)
  .refine(
    (file) => ACCEPTED_IMAGE_TYPES.includes(file?.type),
    'Only .jpg, .jpeg, .png and .webp formats are supported.'
  );

// const imageSchema =
//   // eslint-disable-next-line no-undef
//   (z.custom < Blob) |
//   // eslint-disable-next-line no-undef
//   (File >
//     ((val) => {
//       // eslint-disable-next-line no-undef
//       if (!(val instanceof Blob) && !(val instanceof File)) {
//         throw new Error('Image is required and must be a file');
//       }

//       if (val.size > maxFileSize) {
//         throw new Error('Image size must be less than 3MB');
//       }

//       if (!ACCEPTED_IMAGE_TYPES.includes(val.type)) {
//         throw new Error('Invalid file type. Accepted types are .jpeg, .jpg, .png, .webp');
//       }

//       return val;
//     }));

// const userVisibilitySchema = z.union([
//   z.object({
//     value: z.string(),
//     label: z.string(),
//     id: z.string().optional()
//   }),
//   z
//     .string()
//     .optional()
//     .refine((val) => val === '', { message: 'Invalid selection' }) // Allow empty string
// ]);

export const addAssetSchema = z
  .object({
    assetId: z.union([z.number(), z.null()]).optional(), // This allows the field to be either a number or null, .optional(): Makes the property optional, so it can also be undefined.
    tokenMac: z.string().optional(),
    image: imageSchema,
    make: z.string().min(3, { message: 'Make is required and must be longer than 3 alphabets' }),
    model: z.string().min(3, { message: 'Model is required and must be longer than 3 alphabets' }),
    description: z.string().min(5, { message: 'Description is required and must be longer than 3 alphabets' }),
    owner: z.string().min(4, { message: 'Owner is required' }),
    supplier: z.string().min(4, { message: 'Supplier is required' }),
    category: z.string().min(4, { message: 'Category is required' }),
    tradeSpecific: z.string().optional(),
    dateOfPurchase: z
      .string()
      .min(1, { message: 'Date of purchase is required' })
      .refine(
        (val) => {
          const date = new Date(val);
          return !isNaN(date.getTime());
        },
        { message: 'Invalid date' }
      ),
    purchasePrice: z.string().min(1, { message: 'Purchase Price is required' }),
    operator: z.string().min(1, { message: 'Operator is required' }),
    location: z.string().optional(),
    locationDate: z
      .string()
      .optional()
      .refine(
        (val) => {
          if (!val) return true; // Allow empty or undefined values

          const date = new Date(val);
          return !isNaN(date.getTime());
        },
        { message: 'Invalid date' }
      ),
    insuranceName: z.string().optional(),
    insurancePolicy: z.string().optional(),
    insuranceExpiry: z
      .string()
      .optional()
      .refine(
        (val) => {
          if (!val) return true; // Allow empty or undefined values

          const date = new Date(val);
          return !isNaN(date.getTime());
        },
        { message: 'Invalid date' }
      ),
    maintenanceSchedule: z.string().optional(),
    disposalDate: z
      .string()
      .optional()
      .refine(
        (val) => {
          if (!val) return true; // Allow empty or undefined values
          const date = new Date(val);
          return !isNaN(date.getTime());
        },
        { message: 'Invalid date' }
      ),
    comments: z.string().optional(),
    activeStatus: z.boolean(),
    fixedLocation: z.boolean(),
    mapIcon: z.string().optional(),
    beaconTokenMac: z.boolean(),
    trackerTokenMac: z.boolean(),
    beaconTokenMacInput: z.string().optional(),
    trackerTokenMacInput: z.string().optional(),
    newAssetId: z.union([z.number(), z.null()]).optional()
  })
  .refine(
    (data) =>
      (data.assetId === null && data.newAssetId !== null) || (data.assetId !== null && data.newAssetId === null),
    {
      message: "Either 'assetId' or 'newAssetId' must be provided",
      path: ['assetId', 'newAssetId'] // Error applies to both fields.
    }
  );

export function isAssetIdAndImageNameValid(assetId, imageName) {
  if (assetId == imageName.split('.')[0]) {
    return true;
  } else {
    return false;
  }
}

export function findMissingAssetId(assetIds) {
  const numericIds = assetIds.map(Number);
  const max = Math.max(...numericIds); // Will find highest number
  const min = Math.min(...numericIds); // Will find lowest number

  let missingNumber = [];

  for (let i = min; i <= max; i++) {
    if (!numericIds.includes(i)) {
      // Checking whether i(current value) present in num(argument)
      missingNumber.push(i);
    }
  }
  return missingNumber;
}

// Utility function to calculate missing asset IDs and next new asset ID
export const calculateAssetIds = (rows) => {
  const rowsAssetIds = rows?.map((r) => r.assetId) || [];
  const missingAssetIds = findMissingAssetId(rowsAssetIds);

  const nextAssetId = Math.max(...rowsAssetIds, 0) + 1;

  return { missingAssetIds, nextAssetId };
};

export function isAssetTokenValid(locationSelect, tokenMacInput) {
  if (
    (locationSelect.beaconTokenMac && tokenMacInput.beaconTokenMac) ||
    (!locationSelect.beaconTokenMac && tokenMacInput.beaconTokenMac === '') ||
    (locationSelect.trackerTokenMac && tokenMacInput.trackerTokenMac) ||
    (!locationSelect.trackerTokenMac && tokenMacInput.trackerTokenMac === '') ||
    (locationSelect.fixedLocation && tokenMacInput.location && tokenMacInput.locationDate) ||
    (!locationSelect.fixedLocation && tokenMacInput.location === '' && tokenMacInput.locationDate === '')
  ) {
    return true;
  }
  return false;
}

export function isAssetLocationValid(locationSelect, data) {
  if (
    (locationSelect.beaconTokenMac && data.beaconTokenMacInput) ||
    (!locationSelect.beaconTokenMac && data.beaconTokenMacInput === '') ||
    (locationSelect.trackerTokenMac && data.trackerTokenMacInput) ||
    (!locationSelect.trackerTokenMac && data.trackerTokenMacInput === '') ||
    (locationSelect.fixedLocation && data.location && data.locationDate) ||
    (!locationSelect.fixedLocation && data.location === '' && data.locationDate === '')
  ) {
    return true;
  }
  return false;
}

export const assetIdSchema = z
  .object({
    newAssetId: z.union([z.number(), z.string(), z.null()]).optional(),
    newAssetIdFromMissingIds: z.union([z.number(), z.null()]).optional()
  })
  .refine(
    (data) =>
      (data.newAssetId === null && data.newAssetIdFromMissingIds !== null) ||
      (data.newAssetId !== null && data.newAssetIdFromMissingIds === null) || {
        message: 'Either choose from missing assetIds or create new one.',
        path: ['assetId', 'newAssetId'] // Error applies to both fields.
      }
  );

export function isNewAssetIdValid(newAssetId, nextAssetId) {
  if (Number(newAssetId) > Number(nextAssetId) - 1) return true;
  return false;
}
