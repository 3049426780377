import { differenceInSeconds, parseISO, getUnixTime } from 'date-fns';
import { getDistance } from 'geolib'; // Only import getDistance directly
import axios from 'axios';

export function getFormattedTrackerRecords(trackerRecords) {
  return trackerRecords.map((trackerRecord) => ({
    lat: trackerRecord?.position?.latitude || null,
    long: trackerRecord?.position?.longitude || null,
    deviceName: trackerRecord?.device?.name || null,
    timestamp: trackerRecord.timestamp || null, //microseconds
    engineIgnitionStatus: trackerRecord.engine?.ignition?.status || false,
    vehicleMileage: trackerRecord.vehicle?.mileage || null,
    sleepModeEnum: trackerRecord.sleep?.mode?.enum || null,
    protocolId: trackerRecord.protocol?.id || null,
    positionAltitude: trackerRecord.position?.altitude || null,
    positionDirection: trackerRecord.position?.direction || null,
    positionSpeed: trackerRecord.position?.speed || null,
    gsmSignalLevel: trackerRecord.gsm?.signal?.level || null, //percentage
    batteryCurrent: trackerRecord.battery?.current || null,
    batteryVoltage: trackerRecord.battery?.voltage || null,
    externalPowersourceVoltage: trackerRecord.external?.powersource?.voltage || null,
    movementStatus: trackerRecord.movement?.status || false,
    canEngineRPM: trackerRecord.can?.engine?.rpm || null, //rpm
    canFuelLevel: trackerRecord.can?.fuel?.level || null, //percentage
    canThrottlePedalLevel: trackerRecord.can?.throttle?.pedal?.level || null, //percentage
    canVehicleSpeed: trackerRecord.can?.vehicle?.speed || null, //km/h
    panicButton: trackerRecord?.panic?.button || null,
    crash: trackerRecord?.crash || null,
    deviceId: trackerRecord?.device?.id || null
  }));
}

export function getFormattedAssetWithTokenMac(assetRecords) {
  return assetRecords.map((aRecord) => ({
    //matching with both beacon and tracker
    lat: aRecord?.trackerPosition?.latitude || aRecord?.beaconPosition?.latitude || null,
    long: aRecord?.trackerPosition?.latitude || aRecord?.beaconPosition?.longitude || null,
    deviceName: aRecord?.deviceName || aRecord?.description + '(' + aRecord?.operator + ')' || null,
    //records from beacon
    timestamp: aRecord?.trackerTimestamp || aRecord?.beaconTimestamp || null,
    beaconPower: aRecord?.beaconPower || null,
    beaconMac: aRecord?.beaconMac || null,
    beaconInstanceID: aRecord?.beaconInstanceID || null,
    beaconNamespaceID: aRecord?.beaconNamespaceID || null,
    beaconUUID: aRecord?.beaconUUID || null,
    beaconName: aRecord?.beaconName || null,
    beaconRssi: aRecord?.beaconRssi || null,

    //records from tracker
    engineIgnitionStatus: aRecord?.engineIgnitionStatus || false,
    vehicleMileage: aRecord?.vehicleMileage || null,
    sleepModeEnum: aRecord?.sleepModeEnum || null,
    protocolId: aRecord?.protocolId || null,
    positionAltitude: aRecord?.positionAltitude || null,
    positionDirection: aRecord?.positionDirection || null,
    positionSpeed: aRecord?.positionSpeed || null,
    gsmSignalLevel: aRecord?.gsmSignalLevel || null, //percentage
    batteryCurrent: aRecord?.batteryCurrent || null,
    batteryVoltage: aRecord?.batteryVoltage || null,
    externalPowersourceVoltage: aRecord?.externalPowersourceVoltage || null,
    movementStatus: aRecord?.movementStatus || false,
    canEngineRPM: aRecord?.canEngineRPM || null, //rpm
    canFuelLevel: aRecord?.canFuelLevel || null, //percentage
    canThrottlePedalLevel: aRecord?.canThrottlePedalLevel || null, //percentage
    canVehicleSpeed: aRecord?.canVehicleSpeed || null, //km/h
    panicButton: aRecord?.panicButton || null,
    crash: aRecord?.crash || null,
    deviceId: aRecord?.deviceId || null,

    mapIcon: aRecord?.mapIcon || null,
    category: aRecord?.category || null,
    operator: aRecord?.operator || null,
    owner: aRecord?.owner || null
  }));
}

export function getFormattedRecords(trackerRecords) {
  return trackerRecords.map((trackerRecord) => ({
    lat: trackerRecord.position.latitude,
    long: trackerRecord.position.longitude,
    createdAt: trackerRecord.createdAt,
    // engineIgnitionStatus: trackerRecord.engine?.ignition?.status || false,
    movementStatus: trackerRecord.movement?.status || false
  }));
}

// Function to calculate distances and time intervals
export function calculateDistancesAndIntervals(gpsData) {
  //need to calculate each day distance, gpsData has 1 month data of similar type

  // Calculate total distance traveled in meters
  let totalDistance = 0;
  for (let i = 0; i < gpsData.length - 1; i++) {
    const start = gpsData[i];
    const end = gpsData[i + 1];

    // Check if start or end positions are invalid (missing or zero)
    const startLat = start?.position?.latitude;
    const startLong = start?.position?.longitude;
    const endLat = end?.position?.latitude;
    const endLong = end?.position?.longitude;

    if (
      !startLat ||
      !startLong ||
      !endLat ||
      !endLong || // Check for null/undefined or zero
      startLat === 0 ||
      startLong === 0 ||
      endLat === 0 ||
      endLong === 0
    ) {
      continue; // Skip to the next segment
    }

    const distance = getDistance(
      { latitude: start?.position?.latitude, longitude: start?.position?.longitude },
      { latitude: end?.position?.latitude, longitude: end?.position?.longitude }
    );
    totalDistance += distance / 1000;
  }

  // Calculate total time in seconds
  const startDate = parseISO(gpsData[0].createdAt);
  const endDate = parseISO(gpsData[gpsData.length - 1].createdAt);
  const totalTimeInSeconds = differenceInSeconds(endDate, startDate);

  return Number(totalDistance).toFixed(2);
}

//separate vehicle history based on the  movement status true/false
export function getVehiclePoints(gpsData) {
  // Initialize an array to hold the grouped data
  const groupedData = [];
  let currentGroup = [];

  // Iterate through the movements array
  gpsData.forEach((data, index) => {
    // Create the data array for the current data
    // const dataEntry = [data?.lat, data?.long, data?.createdAt];
    const dataEntry = [data?.lat, data?.long];

    if (data?.movementStatus) {
      // If movementStatus is true, add the entry to the current group
      currentGroup.push(dataEntry); // Always add the true movementStatus entry
    } else {
      // If movementStatus is false
      if (currentGroup.length > 0) {
        // If there are true entries in the current group, add the false entry
        currentGroup.push(dataEntry);
      }
    }

    // When we find a false movementStatus after true statuses
    if (currentGroup.length > 0 && (data?.movementStatus === false || index === gpsData.length - 1)) {
      // Check if we should push the group (only when moving to false or at the end of array)
      if (data?.movementStatus === false || index === gpsData.length - 1) {
        groupedData.push(currentGroup); // Push current group to grouped data
        currentGroup = []; // Reset current group for the next series
      }
    }
  });

  // After iterating, check if there is any ongoing group to push
  if (currentGroup.length > 0) {
    groupedData.push(currentGroup);
  }

  // Output the structured data
  const polylineRecord = JSON.stringify(groupedData, null, 2);

  // Flatten the grouped data into an array of points for the polyline
  const points = groupedData
    .flat()
    .map((point) => {
      const latLng = [point[0], point[1]];
      // Log to ensure lat/long are valid
      return latLng;
    })
    .filter((point) => point[0] !== undefined && point[1] !== undefined); // Filter out undefined values

  return points;
}

//format to group trips
export function getFormattedTripRecord(trackerRecords) {
  return trackerRecords.map((trackerRecord) => ({
    position: { latitude: trackerRecord.position.latitude, longitude: trackerRecord.position.longitude },
    timestamp: trackerRecord.trackerRecord,
    createdAt: trackerRecord.createdAt,
    engineStatus: trackerRecord.engine?.ignition?.status || false,
    movementStatus: trackerRecord.movement?.status || false,
    speed: trackerRecord.position.speed
  }));
}

export function groupTripsByIgnitionAndMovement(data) {
  const trips = [];
  let currentTrip = [];
  const movementThreshold = 5; // Speed threshold in km/h for considering the vehicle is moving
  const idleTimeThreshold = 10 * 60 * 1000; // 10 minutes of idle time before trip is considered ended

  for (let i = 0; i < data.length; i++) {
    const currentPoint = data[i];
    const lastPoint = currentTrip[currentTrip.length - 1];

    // Check if this is the first point or if the vehicle is still moving
    if (currentTrip.length === 0 && currentPoint.engineStatus && currentPoint.speed > movementThreshold) {
      // Start a new trip
      currentTrip.push(currentPoint);
    } else if (currentPoint.engineStatus && currentPoint.speed > movementThreshold) {
      // Continue the trip if the vehicle is moving
      currentTrip.push(currentPoint);
    } else if (
      currentPoint.engineStatus === false ||
      (currentPoint.speed === 0 &&
        new Date(currentPoint?.timestamp) - new Date(lastPoint?.timestamp) > idleTimeThreshold)
    ) {
      // End the trip if the engine is off or vehicle is idle for too long
      trips.push(currentTrip);
      currentTrip = []; // Reset for new trip
    }
  }

  // Add the last trip if any
  if (currentTrip.length > 0) {
    trips.push(currentTrip);
  }

  return trips;
}

//convert seconds to minutes
export function formatDuration(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  if (hours > 0) {
    return `${hours} hours, ${minutes} minutes`;
  }

  return `${minutes} minutes`;
}

//convert timestamp into hours and minutes , beacon/tracker store timestamp in seconds, and unix in milliseconds
//js assume timestamp is in milliseconds that's why needed to convert from seconds to milliseconds in first line
export function getLocalTimeString(unix_timestamp) {
  const date = new Date(unix_timestamp * 1000);

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  // const seconds = date.getSeconds().toString().padStart(2, '0');

  // Determine AM or PM
  const period = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Format time string
  const formattedTime = `${hours}:${minutes} ${period}`;

  return formattedTime;
}

// Example usage
// const durationInSeconds = 8389;
// console.log(formatDuration(durationInSeconds)); // Output: "2 hours, 19 minutes"

export function withInTenMinutes(timestamp) {
  const currentTimestamp = getUnixTime(new Date());
  const tenMinutesAgo = currentTimestamp - 10 * 60;

  return timestamp >= tenMinutesAgo;
}

//if the last known location:timestamp is less than one Week, it needs to return true
export function isLessThanAWeek(timestamp) {
  const currentTimestamp = getUnixTime(new Date()); // Current time in seconds
  const oneWeekAgo = currentTimestamp - 7 * 24 * 60 * 60; // 7 days in seconds

  return timestamp > oneWeekAgo;
}

//fetch the address
export async function fetchAddress(latitude, longitude) {
  const response = await axios.get(
    `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
  );
  const data = response.data;
  const address =
    data && data.display_name
      ? data.display_name
      : data.name
      ? data.name
      : `Address not found at latitude ${latitude} and longitude ${longitude}`;

  return address;
}

//change the timestamp to time
export function convertTimestamp(timestamp) {
  const date = new Date(timestamp);
  const melbourneTime = date.toLocaleTimeString('en-AU', { timeZone: 'Australia/Melbourne' });
  return melbourneTime;
}

//export the array of category/operator/owner
export function createOptions(rows, optionName) {
  // eslint-disable-next-line no-undef
  const uniqueOptions = new Set(rows.map((r) => r[optionName]).filter((option) => option && option.trim() !== ''));
  const options = Array.from(uniqueOptions).map((option) => {
    return { value: option, label: option };
  });
  return options;
}
