import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { parseISO, startOfDay, endOfDay, getUnixTime } from 'date-fns';
import { MapContainer, TileLayer, Polyline, FeatureGroup, Marker, Tooltip } from 'react-leaflet';
import { getMarkerIcon } from '../MarkerIcon';

import bluetoothToolService from '../../../../services/BluetoothToolService';
// import { routeHistory } from './demoData';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  leafletInteractive: {
    strokeWidth: 5,
    stroke: '#03a9f4'
  },
  mapComponentWrapper: {
    width: '100%'
  }
});

const BeaconMapComponent = ({ position, beaconInstanceID, vehicleTabClicked, dateString, beaconMac }) => {
  const classes = useStyles();
  const [beaconPosition, setBeaconPosition] = useState([]);

  useEffect(() => {
    const fetchBeaconRouteHistory = async () => {
      try {
        // Parse the date string
        const date = parseISO(dateString);
        // Get the start and end of the day
        const fromTimestamp = getUnixTime(startOfDay(date)); // 12:00 AM timestamp
        const toTimestamp = getUnixTime(endOfDay(date)); // 11:59 PM timestamp

        const beaconPayload = {
          beaconInstanceID: beaconInstanceID,
          fromTimestamp: fromTimestamp,
          toTimestamp: toTimestamp
        };

        if (beaconMac && vehicleTabClicked === 'route' && dateString) {
          const beaconRouteRecord = await bluetoothToolService.getBeaconRouteHistory(beaconPayload);
          // eslint-disable-next-line no-console, no-undef
          console.log('beaconRouteRecord', beaconRouteRecord);

          const routeHistory = beaconRouteRecord?.data?.data;
          // eslint-disable-next-line no-console, no-undef
          console.log('routeHistory', routeHistory);

          const positions = routeHistory.map((history) => {
            return [history.position.latitude, history.position.longitude];
          });
          setBeaconPosition(positions);
        }
      } catch (error) {
        // eslint-disable-next-line no-console, no-undef
        console.log('error', error);
      }
    };

    fetchBeaconRouteHistory();
  }, [beaconInstanceID, dateString, beaconMac, vehicleTabClicked]);

  // const beaconPosition =
  //   vehicleTabClicked === 'route'
  //     ? routeHistory?.map((rHistory) => {
  //         return [rHistory.position.latitude, rHistory.position.longitude];
  //       })
  //     : null;
  // console.log('beaconPosition', beaconPosition);

  return (
    <div>
      <MapContainer center={position} zoom={11} style={{ height: '100vh', width: '100%' }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {beaconPosition?.map((position, index) => {
          return (
            <FeatureGroup key={index}>
              {index % 2 ? (
                <Marker key={index} position={position} icon={getMarkerIcon('green')}>
                  <Tooltip direction="right" offset={[25, 20]} opacity={1} permanent>
                    <span>Start</span>
                  </Tooltip>
                </Marker>
              ) : (
                <Marker key={index} position={position} icon={getMarkerIcon('red')}>
                  <Tooltip direction="right" offset={[25, 20]} opacity={1} permanent>
                    <span>End</span>
                  </Tooltip>
                </Marker>
              )}

              <Polyline positions={beaconPosition} color="blue" className={classes.leafletInteractive} />
            </FeatureGroup>
          );
        })}
      </MapContainer>
    </div>
  );
};

export default BeaconMapComponent;

BeaconMapComponent.propTypes = {
  position: PropTypes.any,
  beaconInstanceID: PropTypes.any,
  vehicleTabClicked: PropTypes.any,
  dateString: PropTypes.any,
  beaconMac: PropTypes.any
};
