import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import { zodResolver } from '@hookform/resolvers/zod';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import { Checkbox, Paper, useMediaQuery } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  addAssetSchema,
  calculateAssetIds,
  createOptions,
  findMissingAssetId,
  formattedAssets,
  isAssetLocationValid,
  isCommentValid
} from '../table/hepler';
import toast from 'react-hot-toast';
import assetService from '../../../services/AssetService';
import Typography from '@material-ui/core/Typography';
import employeeService from '../../../services/EmployeeService';
import AddAssetLocation from './AddAssetLocation';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      // marginTop: theme.spacing(1)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: 'rgb(107, 108, 114)',
    fontSize: '1rem'
  },
  bootstrapInput: {
    maxWidth: '350px',
    width: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  }
}));

const AddAsset = ({ open, setOpen, rows, setRows, vendorRecords, vendorLoading, vendorError }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [employeeRecords, setEmployeeRecords] = useState();
  const [mapIcon, setMapIcon] = useState('');
  const [missingAssetIdOptions, setMissingAssetIdOptions] = useState([]);
  const [createNewClicked, setCreateNewClicked] = useState(false);
  const [createNewAssetId, setCreateNewAssetId] = useState(null);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset
  } = useForm({
    resolver: zodResolver(addAssetSchema)
  });
  const todayDate = new Date().toISOString().split('T')[0];

  useEffect(() => {
    const { missingAssetIds } = calculateAssetIds(rows);
    const missingIdOptions = missingAssetIds.map((id) => ({
      label: id,
      value: id
    }));

    setMissingAssetIdOptions(missingIdOptions);
  }, [rows]);

  const categoryOptions = createOptions(rows, 'category');
  const ownerOptions = createOptions(rows, 'owner');
  const operatorOptions = createOptions(rows, 'operator');
  const employeeOptions = employeeRecords?.map((employee) => {
    return { value: employee?.DisplayName, label: employee?.DisplayName };
  });

  const assetLocationOptions = [
    {
      value: 'fixedLocation',
      label: 'Fixed Location'
    },
    {
      value: 'beaconTokenMac',
      label: 'Beacon Token Mac'
    },
    {
      value: 'trackerTokenMac',
      label: 'GPS Token Mac'
    }
  ];

  const [locationSelect, setLocationSelect] = useState({
    fixedLocation: false,
    beaconTokenMac: false,
    trackerTokenMac: false
  });

  //get all the active employees to list in owner, while adding new asset, might have to assign to new employees
  useEffect(() => {
    const fetchActiveEmployees = async () => {
      const activeEmployees = await employeeService.getActiveEmployees();

      const activeEmployeesRecords = activeEmployees?.data?.data;

      setEmployeeRecords(activeEmployeesRecords);
    };

    fetchActiveEmployees();
  }, []);

  const handleSelectedIcon = (selectedOption) => {
    setMapIcon(selectedOption?.value || null);
  };

  const onSubmit = (data) => {
    const assetCommentValid = isCommentValid(data.activeStatus, data.comments);

    //check if any location is selected it's input are not empty
    const assetLocation = isAssetLocationValid(locationSelect, data);
    if (!assetLocation) {
      return toast.error('Please fill selected location input.');
    }

    if (!assetCommentValid) {
      return toast.error('If asset is inactive, you must add comments');
    }

    // eslint-disable-next-line no-undef
    const formData = new FormData();
    //append image
    formData.append('file', data.image);

    delete data.image;

    // Append other asset data to FormData
    let newAssetData;
    if (mapIcon) {
      newAssetData = { ...data, mapIcon: mapIcon };
    }
    if (data.beaconTokenMac || data.trackerTokenMac) {
      newAssetData = { ...data, tokenMac: data.beaconTokenMacInput || data.beaconTokenMacInput };
    }
    if (data.assetId === null && data.newAssetId !== null) {
      newAssetData = { ...data, assetId: data.newAssetId };
    } else {
      newAssetData = { ...data };
    }

    if (!newAssetData.assetId) {
      return toast.error('AssetId seems to be null');
    }

    formData.append('newAssetData', JSON.stringify(newAssetData));

    assetService
      .createAsset(formData)
      .then((data) => {
        let assetArray = [];
        assetArray.push(data.data.data);
        const formattedNewAsset = formattedAssets(assetArray);

        toast.success('Successfully created new asset');

        setRows([formattedNewAsset[0], ...rows]);
        reset();

        setLocationSelect({ ...locationSelect, fixedLocation: false, beaconTokenMac: false, trackerTokenMac: false });
        setCreateNewClicked(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'Failed to create new asset');
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const beaconTokenMacClicked = watch('beaconTokenMac', false);
  const trackerTokenMacClicked = watch('trackerTokenMac', false);
  const fixedLocationClicked = watch('fixedLocation', false);

  // Handle toggling between choosing and creating new Asset ID
  const handleAssetSelection = (isCreateNew) => {
    if (isCreateNew) {
      const { nextAssetId } = calculateAssetIds(rows);
      setCreateNewClicked(true);
      setCreateNewAssetId(nextAssetId);
      setValue('newAssetId', nextAssetId); // Set newAssetId in form
      setValue('assetId', null); // Clear assetId in form
    } else {
      setCreateNewClicked(false);
      setCreateNewAssetId(null);
      setValue('newAssetId', null); // Clear newAssetId in form
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">Create new asset</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item container xs={12}>
                <Paper className={classes.paper}>
                  <Grid item container xs={12} spacing={2} pl={isSmall ? 0 : 3}>
                    {/* assetId section */}

                    {/* choose from missing asset ids */}
                    <Grid item xs={12} sm={9}>
                      <Paper className={classes.paper}>
                        <InputLabel shrink htmlFor="AssetId" className={classes.inputLabel}>
                          AssetId
                        </InputLabel>
                        <Grid item container xs={12} spacing={1}>
                          <Grid item xs={12} sm={5}>
                            <span>Choose from missing asset Ids </span>
                            <Controller
                              name="assetId"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <ReactSelect
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={
                                      missingAssetIdOptions?.find((option) => option.value === field.value) || null
                                    }
                                    isSearchable={true}
                                    name="assetId"
                                    options={missingAssetIdOptions}
                                    onChange={(selectedOption) => {
                                      field.onChange(selectedOption ? selectedOption?.value : null);
                                      handleAssetSelection(false);
                                    }}
                                  />
                                  {errors.assetId && (
                                    <p
                                      style={{
                                        color: 'red',
                                        marginTop: '10px',
                                        fontSize: '0.8rem',
                                        lineHeight: '1',
                                        marginBottom: '0px',
                                        paddingBottom: '0px'
                                      }}
                                    >
                                      {errors?.assetId?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <Typography>OR</Typography>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            {!createNewClicked ? (
                              <Button variant="contained" onClick={() => handleAssetSelection(true)}>
                                Create New
                              </Button>
                            ) : (
                              <>
                                <span>Create new </span>
                                <Controller
                                  name="newAssetId"
                                  control={control}
                                  defaultValue={createNewAssetId}
                                  render={({ field }) => (
                                    <BootstrapInput {...field} disabled className={classes.bootstrapInput} />
                                  )}
                                />
                                {errors.newAssetId?.message && (
                                  <p
                                    style={{
                                      color: 'red',
                                      marginTop: '10px',
                                      fontSize: '0.8rem',
                                      lineHeight: '1',
                                      marginBottom: '0px',
                                      paddingBottom: '0px'
                                    }}
                                  >
                                    {errors?.newAssetId?.message}
                                  </p>
                                )}
                              </>
                            )}
                          </Grid>
                          {errors?.assetId?.newAssetId?.message && (
                            <p
                              style={{
                                color: 'red',
                                marginTop: '10px',
                                fontSize: '0.8rem',
                                lineHeight: '1',
                                marginBottom: '0px',
                                paddingBottom: '0px'
                              }}
                            >
                              {errors?.assetId?.newAssetId?.message}
                            </p>
                          )}
                        </Grid>
                      </Paper>
                    </Grid>

                    {/* Image */}
                    <Grid item xs={12} sm={3}>
                      <InputLabel shrink htmlFor="image" className={classes.inputLabel}>
                        Image
                      </InputLabel>
                      <Controller
                        name="image"
                        control={control}
                        render={({ field }) => (
                          <>
                            {
                              // eslint-disable-next-line no-undef
                              field.value instanceof Blob && field?.value && (
                                <div>
                                  <img
                                    // eslint-disable-next-line no-undef
                                    src={URL.createObjectURL(field.value)}
                                    alt="asset"
                                    height={50}
                                    width={100}
                                    style={{ marginRight: '5px', objectFit: 'contain' }}
                                  />
                                </div>
                              )
                            }
                            <Button component="label">
                              Upload
                              <input
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                hidden
                                onChange={(e) => field.onChange(e.target.files[0])}
                              />
                            </Button>
                            {errors.image && (
                              <p
                                style={{
                                  color: 'red',
                                  marginTop: '10px',
                                  fontSize: '0.8rem',
                                  lineHeight: '1',
                                  marginBottom: '0px',
                                  paddingBottom: '0px'
                                }}
                              >
                                {errors?.image?.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </Grid>

                    {/* Location */}
                    <Grid item xs={12}>
                      <InputLabel shrink htmlFor="make" className={classes.inputLabel}>
                        Location
                      </InputLabel>
                      <AddAssetLocation
                        control={control}
                        errors={errors}
                        beaconTokenMacClicked={beaconTokenMacClicked}
                        trackerTokenMacClicked={trackerTokenMacClicked}
                        fixedLocationClicked={fixedLocationClicked}
                        mapIcon={mapIcon}
                        handleSelectedIcon={handleSelectedIcon}
                        setValue={setValue}
                        locationSelect={locationSelect}
                        setLocationSelect={setLocationSelect}
                        assetLocationOptions={assetLocationOptions}
                      />
                    </Grid>

                    {/* Make */}
                    <Grid item xs={12} sm={4}>
                      <InputLabel shrink htmlFor="make" className={classes.inputLabel}>
                        Make
                      </InputLabel>
                      <Controller
                        name="make"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <BootstrapInput {...field} className={classes.bootstrapInput} />
                            {errors.make && (
                              <p
                                style={{
                                  color: 'red',
                                  marginTop: '10px',
                                  fontSize: '0.8rem',
                                  lineHeight: '1',
                                  marginBottom: '0px',
                                  paddingBottom: '0px'
                                }}
                              >
                                {errors?.make?.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </Grid>

                    {/* Model */}
                    <Grid item xs={12} sm={4}>
                      <InputLabel shrink htmlFor="model" className={classes.inputLabel}>
                        Model
                      </InputLabel>
                      <Controller
                        name="model"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <BootstrapInput {...field} className={classes.bootstrapInput} />
                            {errors.model && (
                              <p
                                style={{
                                  color: 'red',
                                  marginTop: '10px',
                                  fontSize: '0.8rem',
                                  lineHeight: '1',
                                  marginBottom: '0px',
                                  paddingBottom: '0px'
                                }}
                              >
                                {errors?.model?.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </Grid>

                    {/* Description */}
                    <Grid item xs={12} sm={4}>
                      <InputLabel shrink htmlFor="description" className={classes.inputLabel}>
                        Description
                      </InputLabel>
                      <Controller
                        name="description"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <BootstrapInput {...field} className={classes.bootstrapInput} />
                            {errors.description && (
                              <p
                                style={{
                                  color: 'red',
                                  marginTop: '10px',
                                  fontSize: '0.8rem',
                                  lineHeight: '1',
                                  marginBottom: '0px',
                                  paddingBottom: '0px'
                                }}
                              >
                                {errors?.description?.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </Grid>

                    {/* owner */}
                    <Grid item xs={12} sm={12}>
                      <Paper className={classes.paper}>
                        <InputLabel shrink htmlFor="owner" className={classes.inputLabel}>
                          Owner
                        </InputLabel>
                        <Grid item container xs={12} spacing={1}>
                          <Grid item xs={12} sm={5}>
                            <span>Choose from existing owners </span>
                            <Controller
                              name="owner"
                              control={control}
                              // render={({ field }) => <BootstrapInput {...field} className={classes.bootstrapInput} />}
                              render={({ field }) => (
                                <>
                                  <ReactSelect
                                    className="basic-single"
                                    classNamePrefix="select"
                                    //  defaultValue={null}
                                    value={ownerOptions?.find((option) => option.value === field.value) || null}
                                    isSearchable={true}
                                    name="owner"
                                    options={ownerOptions}
                                    // onChange={(selectedOption) => {
                                    //   return handleAssignedAssetItem(fi);
                                    // }}
                                    onChange={(selectedOption) =>
                                      field.onChange(selectedOption ? selectedOption?.value : null)
                                    }
                                  />
                                  {errors.owner && (
                                    <p
                                      style={{
                                        color: 'red',
                                        marginTop: '10px',
                                        fontSize: '0.8rem',
                                        lineHeight: '1',
                                        marginBottom: '0px',
                                        paddingBottom: '0px'
                                      }}
                                    >
                                      {errors?.owner?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <Typography>OR</Typography>
                          </Grid>

                          <Grid item xs={12} sm={5}>
                            <span>Enter a new one</span>
                            <Controller
                              name="owner"
                              control={control}
                              // render={({ field }) => <BootstrapInput {...field} className={classes.bootstrapInput} />}
                              render={({ field }) => (
                                <>
                                  <ReactSelect
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={employeeOptions?.find((option) => option.value === field.value) || null}
                                    isSearchable={true}
                                    name="owner"
                                    options={employeeOptions}
                                    onChange={(selectedOption) =>
                                      field.onChange(selectedOption ? selectedOption?.value : null)
                                    }
                                  />
                                  {errors.owner && (
                                    <p
                                      style={{
                                        color: 'red',
                                        marginTop: '10px',
                                        fontSize: '0.8rem',
                                        lineHeight: '1',
                                        marginBottom: '0px',
                                        paddingBottom: '0px'
                                      }}
                                    >
                                      {errors?.owner?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>

                    {/* Supplier */}
                    <Grid item xs={12} sm={12}>
                      <Paper className={classes.paper}>
                        <InputLabel shrink htmlFor="supplier" className={classes.inputLabel}>
                          Supplier
                        </InputLabel>
                        {vendorLoading ? (
                          <Grid item container xs={12} spacing={1}>
                            <CircularProgress size={'1.5rem'} />
                          </Grid>
                        ) : vendorError ? (
                          <Grid item container xs={12} spacing={1}>
                            {vendorError}
                          </Grid>
                        ) : (
                          <Grid item container xs={12} spacing={1}>
                            <Grid item xs={12} sm={5}>
                              <span>Choose from existing supplier </span>
                              <Controller
                                name="supplier"
                                control={control}
                                // render={({ field }) => <BootstrapInput {...field} className={classes.bootstrapInput} />}
                                render={({ field }) => (
                                  <>
                                    <ReactSelect
                                      className="basic-single"
                                      classNamePrefix="select"
                                      value={vendorRecords.find((option) => option.value === field.value) || null}
                                      isSearchable={true}
                                      name="supplier"
                                      options={vendorRecords}
                                      onChange={(selectedOption) =>
                                        field.onChange(selectedOption ? selectedOption?.value : null)
                                      }
                                    />
                                    {errors.supplier && (
                                      <p
                                        style={{
                                          color: 'red',
                                          marginTop: '10px',
                                          fontSize: '0.8rem',
                                          lineHeight: '1',
                                          marginBottom: '0px',
                                          paddingBottom: '0px'
                                        }}
                                      >
                                        {errors?.supplier?.message}
                                      </p>
                                    )}
                                  </>
                                )}
                              />
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              sm={2}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <Typography>OR</Typography>
                            </Grid>

                            <Grid item xs={12} sm={5}>
                              <span>Enter a new one</span>
                              <Controller
                                name="supplier"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <>
                                    <BootstrapInput {...field} className={classes.bootstrapInput} />
                                    {errors.supplier && (
                                      <p
                                        style={{
                                          color: 'red',
                                          marginTop: '10px',
                                          fontSize: '0.8rem',
                                          lineHeight: '1',
                                          marginBottom: '0px',
                                          paddingBottom: '0px'
                                        }}
                                      >
                                        {errors?.supplier?.message}
                                      </p>
                                    )}
                                  </>
                                )}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Paper>
                    </Grid>

                    {/* Category */}
                    <Grid item xs={12} sm={12}>
                      <Paper className={classes.paper}>
                        <InputLabel shrink htmlFor="category" className={classes.inputLabel}>
                          Category
                        </InputLabel>

                        <Grid item container xs={12} spacing={1}>
                          <Grid item xs={12} sm={5}>
                            <span>Choose from existing category </span>

                            <Controller
                              name="category"
                              control={control}
                              // render={({ field }) => <BootstrapInput {...field} className={classes.bootstrapInput} />}
                              render={({ field }) => (
                                <>
                                  <ReactSelect
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={categoryOptions.find((option) => option.value === field.value) || null}
                                    isSearchable={true}
                                    name="category"
                                    options={categoryOptions}
                                    onChange={(selectedOption) => field.onChange(selectedOption.value)}
                                  />
                                  {errors.category && (
                                    <p
                                      style={{
                                        color: 'red',
                                        marginTop: '10px',
                                        fontSize: '0.8rem',
                                        lineHeight: '1',
                                        marginBottom: '0px',
                                        paddingBottom: '0px'
                                      }}
                                    >
                                      {errors?.category?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <Typography>OR</Typography>
                          </Grid>

                          <Grid item xs={12} sm={5}>
                            <span>Enter a new one</span>
                            <Controller
                              name="category"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <>
                                  <BootstrapInput {...field} className={classes.bootstrapInput} />
                                  {errors.supplier && (
                                    <p
                                      style={{
                                        color: 'red',
                                        marginTop: '10px',
                                        fontSize: '0.8rem',
                                        lineHeight: '1',
                                        marginBottom: '0px',
                                        paddingBottom: '0px'
                                      }}
                                    >
                                      {errors?.category?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>

                    {/* Operator */}
                    <Grid item xs={12} sm={12}>
                      <Paper className={classes.paper}>
                        <InputLabel shrink htmlFor="operator" className={classes.inputLabel}>
                          Operator
                        </InputLabel>

                        <Grid item container xs={12} spacing={1}>
                          <Grid item xs={12} sm={5}>
                            <span>Choose from existing operator </span>
                            <Controller
                              name="operator"
                              control={control}
                              // render={({ field }) => <BootstrapInput {...field} className={classes.bootstrapInput} />}
                              render={({ field }) => (
                                <>
                                  <ReactSelect
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={operatorOptions.find((option) => option.value === field.value) || null}
                                    isSearchable={true}
                                    name="operator"
                                    options={operatorOptions}
                                    onChange={(selectedOption) => field.onChange(selectedOption.value)}
                                  />
                                  {errors.operator && (
                                    <p
                                      style={{
                                        color: 'red',
                                        marginTop: '10px',
                                        fontSize: '0.8rem',
                                        lineHeight: '1',
                                        marginBottom: '0px',
                                        paddingBottom: '0px'
                                      }}
                                    >
                                      {errors?.operator?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={2}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <Typography>OR</Typography>
                          </Grid>

                          <Grid item xs={12} sm={5}>
                            <span>Enter a new one</span>
                            <Controller
                              name="operator"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <>
                                  <BootstrapInput {...field} className={classes.bootstrapInput} />
                                  {errors.operator && (
                                    <p
                                      style={{
                                        color: 'red',
                                        marginTop: '10px',
                                        fontSize: '0.8rem',
                                        lineHeight: '1',
                                        marginBottom: '0px',
                                        paddingBottom: '0px'
                                      }}
                                    >
                                      {errors?.operator?.message}
                                    </p>
                                  )}
                                </>
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>

                    {/*Trade Specific  */}
                    <Grid item xs={12} sm={4}>
                      <InputLabel shrink htmlFor="tradeSpecific" className={classes.inputLabel}>
                        Trade Specific
                      </InputLabel>
                      <Controller
                        name="tradeSpecific"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <BootstrapInput {...field} className={classes.bootstrapInput} />
                            {errors.tradeSpecific && (
                              <p
                                style={{
                                  color: 'red',
                                  marginTop: '10px',
                                  fontSize: '0.8rem',
                                  lineHeight: '1',
                                  marginBottom: '0px',
                                  paddingBottom: '0px'
                                }}
                              >
                                {errors?.tradeSpecific?.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </Grid>

                    {/* Date of purchase */}
                    <Grid item xs={12} sm={4}>
                      <InputLabel shrink htmlFor="dateOfPurchase" className={classes.inputLabel}>
                        Date of purchase
                      </InputLabel>
                      <Controller
                        name="dateOfPurchase"
                        control={control}
                        defaultValue={todayDate}
                        render={({ field }) => (
                          <>
                            <BootstrapInput {...field} className={classes.bootstrapInput} type="date" />
                            {errors.dateOfPurchase && (
                              <p
                                style={{
                                  color: 'red',
                                  marginTop: '10px',
                                  fontSize: '0.8rem',
                                  lineHeight: '1',
                                  marginBottom: '0px',
                                  paddingBottom: '0px'
                                }}
                              >
                                {errors?.dateOfPurchase?.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </Grid>

                    {/* purchasePrice */}
                    <Grid item xs={12} sm={4}>
                      <InputLabel shrink htmlFor="purchasePrice" className={classes.inputLabel}>
                        Purchase Price
                      </InputLabel>
                      <Controller
                        name="purchasePrice"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <BootstrapInput {...field} className={classes.bootstrapInput} type="number" />
                            {errors.purchasePrice && (
                              <p
                                style={{
                                  color: 'red',
                                  marginTop: '10px',
                                  fontSize: '0.8rem',
                                  lineHeight: '1',
                                  marginBottom: '0px',
                                  paddingBottom: '0px'
                                }}
                              >
                                {errors?.purchasePrice?.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </Grid>

                    {/* insuranceName */}
                    <Grid item xs={12} sm={4}>
                      <InputLabel shrink htmlFor="insuranceName" className={classes.inputLabel}>
                        Insurance Name
                      </InputLabel>
                      <Controller
                        name="insuranceName"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <BootstrapInput {...field} className={classes.bootstrapInput} />
                            {errors.insuranceName && (
                              <p
                                style={{
                                  color: 'red',
                                  marginTop: '10px',
                                  fontSize: '0.8rem',
                                  lineHeight: '1',
                                  marginBottom: '0px',
                                  paddingBottom: '0px'
                                }}
                              >
                                {errors?.insuranceName?.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </Grid>

                    {/* insurancePolicy */}
                    <Grid item xs={12} sm={4}>
                      <InputLabel shrink htmlFor="insurancePolicy" className={classes.inputLabel}>
                        Insurance Policy
                      </InputLabel>
                      <Controller
                        name="insurancePolicy"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <BootstrapInput {...field} className={classes.bootstrapInput} />
                            {errors.insurancePolicy && (
                              <p
                                style={{
                                  color: 'red',
                                  marginTop: '10px',
                                  fontSize: '0.8rem',
                                  lineHeight: '1',
                                  marginBottom: '0px',
                                  paddingBottom: '0px'
                                }}
                              >
                                {errors?.insurancePolicy?.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </Grid>

                    {/* insuranceExpiry */}
                    <Grid item xs={12} sm={4}>
                      <InputLabel shrink htmlFor="insuranceExpiry" className={classes.inputLabel}>
                        Insurance Expiry
                      </InputLabel>
                      <Controller
                        name="insuranceExpiry"
                        control={control}
                        defaultValue={todayDate}
                        render={({ field }) => (
                          <>
                            <BootstrapInput {...field} className={classes.bootstrapInput} type="date" />
                            {errors.insuranceExpiry && (
                              <p
                                style={{
                                  color: 'red',
                                  marginTop: '10px',
                                  fontSize: '0.8rem',
                                  lineHeight: '1',
                                  marginBottom: '0px',
                                  paddingBottom: '0px'
                                }}
                              >
                                {errors?.insuranceExpiry?.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </Grid>

                    {/* maintenanceSchedule */}
                    <Grid item xs={12} sm={4}>
                      <InputLabel shrink htmlFor="maintenanceSchedule" className={classes.inputLabel}>
                        MaintenanceSchedule
                      </InputLabel>
                      <Controller
                        name="maintenanceSchedule"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <BootstrapInput {...field} className={classes.bootstrapInput} />
                            {errors.maintenanceSchedule && (
                              <p
                                style={{
                                  color: 'red',
                                  marginTop: '10px',
                                  fontSize: '0.8rem',
                                  lineHeight: '1',
                                  marginBottom: '0px',
                                  paddingBottom: '0px'
                                }}
                              >
                                {errors?.maintenanceSchedule?.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </Grid>

                    {/* disposalDate */}
                    <Grid item xs={12} sm={4}>
                      <InputLabel shrink htmlFor="disposalDate" className={classes.inputLabel}>
                        Disposal Date
                      </InputLabel>
                      <Controller
                        name="disposalDate"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <BootstrapInput {...field} className={classes.bootstrapInput} type="date" />
                            {errors.disposalDate && (
                              <p
                                style={{
                                  color: 'red',
                                  marginTop: '10px',
                                  fontSize: '0.8rem',
                                  lineHeight: '1',
                                  marginBottom: '0px',
                                  paddingBottom: '0px'
                                }}
                              >
                                {errors?.disposalDate?.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </Grid>

                    {/* active status */}
                    <Grid item xs={12} sm={4}>
                      <InputLabel shrink htmlFor="activeStatus" className={classes.inputLabel}>
                        Active Status
                      </InputLabel>
                      <Controller
                        name="activeStatus"
                        control={control}
                        defaultValue={true}
                        // render={({ field }) => <BootstrapInput {...field} className={classes.bootstrapInput} />}
                        render={({ field }) => (
                          <>
                            <Checkbox
                              checked={field.value}
                              onChange={(e) => field.onChange(e.target.checked)}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            {errors.activeStatus && (
                              <p
                                style={{
                                  color: 'red',
                                  marginTop: '10px',
                                  fontSize: '0.8rem',
                                  lineHeight: '1',
                                  marginBottom: '0px',
                                  paddingBottom: '0px'
                                }}
                              >
                                {errors?.activeStatus?.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </Grid>

                    {/* comments */}
                    <Grid item xs={12} sm={4}>
                      <InputLabel shrink htmlFor="comments" className={classes.inputLabel}>
                        Comments
                      </InputLabel>
                      <Controller
                        name="comments"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <BootstrapInput {...field} className={classes.bootstrapInput} />
                            {errors.comments && (
                              <p
                                style={{
                                  color: 'red',
                                  marginTop: '10px',
                                  fontSize: '0.8rem',
                                  lineHeight: '1',
                                  marginBottom: '0px',
                                  paddingBottom: '0px'
                                }}
                              >
                                {errors?.comments?.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <DialogActions style={{ padding: '20px 23px 16px 0' }}>
              <Button onClick={handleClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddAsset;

AddAsset.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  rows: PropTypes.array.isRequired,
  setRows: PropTypes.func.isRequired,
  employeeRefs: PropTypes.any,
  vendorRecords: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  vendorLoading: PropTypes.bool,
  vendorError: PropTypes.string
};
