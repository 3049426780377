/* eslint-disable react/prop-types */

// All assets listed on register shown in table format. Inclusive of image
// Assets to have visibility settings so certain users can only view certain assets.

import React, { useCallback, useRef, useState } from 'react';
import { format } from 'date-fns';

import Paper from '@mui/material/Paper';
import {
  SortingState,
  PagingState,
  IntegratedPaging,
  IntegratedSorting,
  DataTypeProvider,
  IntegratedFiltering,
  IntegratedSelection,
  SelectionState,
  EditingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableColumnVisibility,
  ColumnChooser,
  Toolbar,
  TableColumnResizing,
  DragDropProvider,
  TableSelection,
  TableColumnReordering,
  ExportPanel,
  TableEditColumn
} from '@devexpress/dx-react-grid-material-ui';
import { Plugin, Template, TemplateConnector, TemplatePlaceholder } from '@devexpress/dx-react-core';
import Dialog from '@mui/material/Dialog';

import { useLocalStorage } from 'react-use';
import ImageFullScreenDialog from '../../../shared/dialog/ImageFullScreenDialog';
import AssetTab from './AssetTab';
import AssetGeneralDialog from '../tabs/General/AssetGeneralDialog';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { isCommentValid } from './hepler';
import toast from 'react-hot-toast';
import assetService from '../../../services/AssetService';
import { Checkbox } from '@material-ui/core';
import { isLessThanAWeek, withInTenMinutes } from '../../VehicleTracker/helper/helper';
import AssetLocation from '../tabs/AssetLocation/AssetLocation';
import AssetLogs from '../tabs/AssetLogs/AssetLogs';
// import Map from '../../../shared/maps/Map';

const Popup = ({
  row,
  rows,
  setRows,
  onChange,
  onApplyChanges,
  onCancelChanges,
  open,
  assetTabIndex,
  setAssetTabIndex,
  vendorRecords,
  vendorLoading,
  vendorError,
  employeeRefs
}) => {
  return (
    <Dialog open={open} onClose={onCancelChanges} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      {/* Dialog contains tab and tab contents: CustomerTab is tab part */}
      <AssetTab assetTabIndex={assetTabIndex} setAssetTabIndex={setAssetTabIndex} />

      {/* General and Accounting is the content of tab  */}
      {assetTabIndex === 0 && (
        <AssetGeneralDialog
          row={row}
          onChange={onChange}
          onApplyChanges={onApplyChanges}
          onCancelChanges={onCancelChanges}
          setRows={setRows}
          rows={rows}
          vendorRecords={vendorRecords}
          vendorLoading={vendorLoading}
          vendorError={vendorError}
          employeeRefs={employeeRefs}
        />
      )}
      {assetTabIndex === 1 && (
        <AssetLocation
          row={row}
          onCancelChanges={onCancelChanges}
          setAssetTabIndex={setAssetTabIndex}
          setRows={setRows}
          rows={rows}
        />
      )}
      {assetTabIndex === 2 && (
        <AssetLogs
          row={row}
          onCancelChanges={onCancelChanges}
          setAssetTabIndex={setAssetTabIndex}
          setRows={setRows}
          rows={rows}
        />
      )}
    </Dialog>
  );
};

// eslint-disable-next-line react/display-name
const PopupEditing = React.memo(
  ({
    popupComponent: Popup,
    setRows,
    assetTabIndex,
    setAssetTabIndex,
    vendorRecords,
    vendorLoading,
    vendorError,
    employeeRefs
  }) => (
    <Plugin>
      <Template name="popupEditing">
        <TemplateConnector>
          {(
            { rows, getRowId, addedRows, editingRowIds, createRowChange, rowChanges },
            {
              changeRow,
              changeAddedRow,
              commitChangedRows,
              commitAddedRows,
              stopEditRows,
              cancelAddedRows,
              cancelChangedRows
            }
          ) => {
            const isNew = addedRows?.length > 0;
            let editedRow;
            let rowId;
            if (isNew) {
              rowId = 0;
              editedRow = addedRows[rowId];
            } else {
              [rowId] = editingRowIds;
              const targetRow = rows.filter((row) => getRowId(row) === rowId)[0];
              editedRow = { ...targetRow, ...rowChanges[rowId] };
            }

            const processValueChange = async ({ target: { value, name } }) => {
              // const { name, value, files } = event.target;

              // console.log('event.target', event.target);
              // let changeArgs;

              // console.log('name, value, files', name, value);

              // if (name === 'image') {
              //   // const file = files[0];
              //   changeArgs = {
              //     rowId,
              //     change: createRowChange(editedRow, value, name)
              //   };
              // } else {
              //   changeArgs = {
              //     rowId,
              //     change: createRowChange(editedRow, value, name)
              //   };
              // }

              const changeArgs = {
                rowId,
                change: createRowChange(editedRow, value, name)
              };
              if (isNew) {
                changeAddedRow(changeArgs);
              } else {
                changeRow(changeArgs);
              }
            };
            const rowIds = isNew ? [0] : editingRowIds;
            const applyChanges = () => {
              if (isNew) {
                commitAddedRows({ rowIds });
              } else {
                stopEditRows({ rowIds });
                commitChangedRows({ rowIds });
              }
            };
            const cancelChanges = () => {
              if (isNew) {
                cancelAddedRows({ rowIds });
              } else {
                stopEditRows({ rowIds });
                cancelChangedRows({ rowIds });
                setAssetTabIndex(0);
              }
            };

            const open = editingRowIds.length > 0 || isNew;
            return (
              <Popup
                open={open}
                row={editedRow}
                onChange={processValueChange}
                onApplyChanges={applyChanges}
                onCancelChanges={cancelChanges}
                assetTabIndex={assetTabIndex}
                setAssetTabIndex={setAssetTabIndex}
                setRows={setRows}
                rows={rows}
                vendorRecords={vendorRecords}
                vendorLoading={vendorLoading}
                vendorError={vendorError}
                employeeRefs={employeeRefs}
              />
            );
          }}
        </TemplateConnector>
      </Template>
      <Template name="root">
        <TemplatePlaceholder />
        <TemplatePlaceholder name="popupEditing" />
      </Template>
    </Plugin>
  )
);

const onSave = (workbook) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    // eslint-disable-next-line no-undef
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
  });
};

const ImageProvider = (props) => {
  return <DataTypeProvider formatterComponent={ImageFullScreenDialog} {...props} />;
};

const LocationFormatter = (props) => {
  if (props?.row?.beaconTimestamp) {
    if (withInTenMinutes(props?.row?.beaconTimestamp)) {
      return <span style={{ color: 'green' }}> {props.value}</span>;
    }
    if (isLessThanAWeek(props?.row?.beaconTimestamp)) {
      return <span style={{ color: 'orange' }}> {props.value}</span>;
    } else {
      return <span style={{ color: 'red' }}> {props.value}</span>;
    }
  }

  return <span> {props.value}</span>;
};

const DateFormatter = ({ value }) => <span>{value ? format(new Date(value), 'dd/MM/yyyy') : null}</span>;
const PurchasePriceFormatter = ({ value }) => <span>{value ? `$${value}` : null}</span>;

const CheckboxProvider = (props) => {
  return <DataTypeProvider formatterComponent={CheckboxFormatter} {...props} />;
};

const ActiveStatusProvider = (props) => {
  return <DataTypeProvider formatterComponent={ActiveStatusFormatter} {...props} />;
};

const CheckboxFormatter = ({ value }) => (
  <Checkbox checked={value} inputProps={{ 'aria-label': 'primary checkbox' }} color="primary" disabled />
);

const ActiveStatusFormatter = ({ value }) => (
  // <Checkbox checked={value} inputProps={{ 'aria-label': 'primary checkbox' }} color="primary" disabled />
  <span>{value === true ? 'Active' : 'Inactive'}</span>
);

const DateTypeProvider = (props) => <DataTypeProvider {...props} formatterComponent={DateFormatter} />;
const PurchasePriceProvider = (props) => <DataTypeProvider {...props} formatterComponent={PurchasePriceFormatter} />;
const LocationProvider = (props) => <DataTypeProvider {...props} formatterComponent={LocationFormatter} />;

const getRowId = (row) => row.id;

//TODO pass setRows in AssetTable from Asset.js
export default function AssetTable({
  rows,
  setRows,
  columns,
  sorting,
  setSorting,
  currentPage,
  setCurrentPage,
  pageSize,
  handlePageSizeChange,
  pageSizes,
  hiddenColumnNames,
  setHiddenColumnNames,
  selection,
  setSelection,
  vendorRecords,
  vendorLoading,
  vendorError,
  employeeRefs
}) {
  const [defaultColumnWidths, setDefaultColumnWidths] = useLocalStorage('defaultAssetsColumnWidths', [
    { columnName: 'assetId', width: 180 },
    { columnName: 'tokenMac', width: 180 },
    { columnName: 'make', width: 180 },
    { columnName: 'model', width: 180 },
    { columnName: 'description', width: 180 },
    { columnName: 'tradeSpecific', width: 180 },
    { columnName: 'supplier', width: 180 },
    { columnName: 'category', width: 180 },
    { columnName: 'dateOfPurchase', width: 180 },
    { columnName: 'purchasePrice', width: 180 },
    { columnName: 'owner', width: 180 },
    { columnName: 'operator', width: 180 },
    { columnName: 'location', width: 180 },
    { columnName: 'locationDate', width: 180 },
    { columnName: 'insuranceName', width: 180 },
    { columnName: 'insurancePolicy', width: 180 },
    { columnName: 'insuranceExpiry', width: 180 },
    { columnName: 'maintenanceSchedule', width: 180 },
    { columnName: 'disposalDate', width: 180 },
    { columnName: 'image', width: 180 },
    { columnName: 'comments', width: 180 },
    { columnName: 'activeStatus', width: 180 },
    { columnName: 'fixedLocation', width: 180 }
    // { columnName: 'maps', width: 180 }
  ]);
  const [columnOrder, setColumnOrder] = useLocalStorage('defaultAssetsColumnOrder', [
    'assetId',
    'tokenMac',
    'make',
    'model',
    'description',
    'tradeSpecific',
    'supplier',
    'category',
    'dateOfPurchase',
    'purchasePrice',
    'owner',
    'operator',
    'location',
    'locationDate',
    'insuranceName',
    'insurancePolicy',
    'insuranceExpiry',
    'maintenanceSchedule',
    'disposalDate',
    'image',
    'comments',
    'activeStatus',
    'fixedLocation'
    // 'maps'
  ]);
  const [imageColumn] = useState(['image']);
  const [dateColumn] = useState(['dateOfPurchase', 'locationDate']);
  const [assetTabIndex, setAssetTabIndex] = useState(0);
  const [activeStatusColumn] = useState(['activeStatus']);
  const [checkboxColumn] = useState(['fixedLocation']);
  const [purchasePriceColumn] = useState(['purchasePrice']);
  const [locationColumn] = useState(['location']);

  // const [mapColumn] = useState(['maps']);

  const deleteRows = (deletedIds) => {
    const rowsForDelete = rows.slice();
    deletedIds.forEach((rowId) => {
      const index = rowsForDelete.findIndex((row) => row.id === rowId);
      if (index > -1) {
        rowsForDelete.splice(index, 1);
      }
    });
    return rowsForDelete;
  };

  const commitChanges = ({ changed, deleted }) => {
    let changedRows;

    if (changed) {
      changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));

      //changed asset
      const changedRowId = Object.keys(changed)[0];

      //changed assetId's details
      const changedRow = changedRows.find((r) => r.id === changedRowId);

      let assetRecord = changed[changedRowId];

      // if (changed[changedRowId]?.image) {
      //   const file = changed[changedRowId].image;

      //   const changedAssetImage = isChangedAssetImageValid(changedRow.assetId, file);

      //   if (!changedAssetImage) {
      //     return toast.error('Uploaded image name should match with assetId');
      //   }
      // }

      //if activeStatus is false, comments must include
      if (changedRow.activeStatus === false) {
        const assetCommentValid = isCommentValid(changedRow.activeStatus, changedRow.comments);

        if (!assetCommentValid) {
          return toast.error('If asset is inactive comments must be added');
        }
      }

      // eslint-disable-next-line no-undef
      const formData = new FormData();
      let assetObject = {
        assetId: changedRow.assetId
      };

      Object.entries(assetRecord).forEach(([key, value]) => {
        if (key === 'image') {
          formData.append('file', value);
        } else {
          assetObject[key] = value;
        }
      });

      formData.append('assetRecord', JSON.stringify(assetObject));

      assetService
        .updateAssetAndImage(formData)
        .then((data) => {
          const updatedRow = data.data.data;

          const updatedRows = changedRows.map((row) => {
            if (row.id === updatedRow._id) {
              return { ...row, ...data.data.data };
            }
            return row;
          });

          setRows(updatedRows);

          toast.success('Succesfully updated asset');
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Could not find asset to update');
        });
    }

    if (deleted) {
      // eslint-disable-next-line no-undef
      if (window.confirm('Are you sure you want to delete this row?')) {
        changedRows = deleteRows(deleted);

        assetService
          .deleteAsset(deleted[0])
          .then(() => {
            toast.success('Successfully deleted selected asset');
            setRows(changedRows);
          })
          .catch((error) => {
            toast.error(
              error?.response?.data?.error || error?.response?.data?.message || 'Could not delete selected asset'
            );
          });
      }
    }

    // setRows(changedRows);
  };

  //customize description cell before downloading
  const customizeCell = (cell, row, column) => {
    if (column.name === 'dateOfPurchase') {
      cell.value = cell?.value && format(new Date(row?.dateOfPurchase), 'dd/MM/yyyy');
    }
    if (column.name === 'locationDate') {
      cell.value = cell?.value && format(new Date(row?.locationDate), 'dd/MM/yyyy');
    }
    if (column.name === 'disposalDate') {
      cell.value = cell?.value && format(new Date(row?.disposalDate), 'dd/MM/yyyy');
    }
    if (column.name === 'insuranceExpiry') {
      cell.value = cell?.value && format(new Date(row?.insuranceExpiry), 'dd/MM/yyyy');
    }
  };

  const exporterRef = useRef(null);

  const startExport = useCallback(
    (options) => {
      exporterRef.current.exportGrid(options);
    },
    [exporterRef]
  );

  return (
    <div>
      <div style={{ margin: '10px 0px' }}>
        <div>Total rows selected: {selection ? selection.length : 0}</div>
      </div>
      <Paper>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <EditingState onCommitChanges={commitChanges} />
          <ImageProvider for={imageColumn} />
          <ActiveStatusProvider for={activeStatusColumn} />
          <CheckboxProvider for={checkboxColumn} />
          <PurchasePriceProvider for={purchasePriceColumn} />
          <LocationProvider for={locationColumn} />

          {/* <MapProvider for={mapColumn} /> */}

          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={handlePageSizeChange}
          />

          <SortingState sorting={sorting} onSortingChange={setSorting} />
          <IntegratedSorting />

          <SelectionState selection={selection} onSelectionChange={setSelection} />
          <IntegratedPaging />
          <IntegratedSelection />

          <DateTypeProvider for={dateColumn} />

          <IntegratedFiltering />

          <DragDropProvider />

          <Table />

          <TableColumnResizing
            defaultColumnWidths={defaultColumnWidths}
            onColumnWidthsChange={setDefaultColumnWidths}
          />
          <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
          <TableHeaderRow showSortingControls />
          <TableSelection showSelectAll />
          <PagingPanel pageSizes={pageSizes} />
          <TableEditColumn showEditCommand showDeleteCommand />
          <PopupEditing
            popupComponent={Popup}
            assetTabIndex={assetTabIndex}
            setAssetTabIndex={setAssetTabIndex}
            setRows={setRows}
            rows={rows}
            vendorRecords={vendorRecords}
            vendorLoading={vendorLoading}
            vendorError={vendorError}
            employeeRefs={employeeRefs}
          />
          <TableColumnVisibility
            hiddenColumnNames={hiddenColumnNames}
            onHiddenColumnNamesChange={setHiddenColumnNames}
          />

          <Toolbar />
          <ColumnChooser />
          <ExportPanel startExport={startExport} />
        </Grid>
        <GridExporter
          ref={exporterRef}
          columns={columns}
          rows={rows}
          selection={selection}
          onSave={onSave}
          getRowId={getRowId}
          hiddenColumnNames={hiddenColumnNames}
          customizeCell={customizeCell}
        />
      </Paper>
    </div>
  );
}
