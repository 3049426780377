import React from 'react';
import MapComponent from './mapComponent/MapComponent';

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  rightBarWrapper: {
    display: 'flex',
    flex: '1',
    border: '1px solid grey'
  }
});

const VehicleTrackerRightBar = ({
  deviceId,
  vehicleTabClicked,
  dateString,
  beaconMac,
  setTrackerItemClicked,
  handleClickedMapItem
}) => {
  const classes = useStyles();

  return (
    <div className={classes.rightBarWrapper}>
      <MapComponent
        deviceId={deviceId}
        vehicleTabClicked={vehicleTabClicked}
        dateString={dateString}
        beaconMac={beaconMac}
        setTrackerItemClicked={setTrackerItemClicked}
        handleClickedMapItem={handleClickedMapItem}
      />
    </div>
  );
};

export default VehicleTrackerRightBar;

VehicleTrackerRightBar.propTypes = {
  deviceId: PropTypes.any,
  vehicleTabClicked: PropTypes.any,
  dateString: PropTypes.any,
  beaconMac: PropTypes.any,
  setTrackerItemClicked: PropTypes.func,
  handleClickedMapItem: PropTypes.func,
  filter: PropTypes.shape({
    legendColor: PropTypes.string,
    searchInput: PropTypes.string,
    trackerState: PropTypes.string,
    filterAssetBy: PropTypes.any,
    skip: PropTypes.number
  })
};
