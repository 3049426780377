import { useEffect, useState } from 'react';

import employeeService from '../../../services/EmployeeService';
import { getFormattedEmployeeRecords } from '../../admin/AdminTimesheet/helper/helper';

export default function useFetchActiveEmployeeRefRecords() {
  const [employeeRefs, setEmployeeRefs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchActiveEmployeeRefRecords = async () => {
      try {
        setLoading(true);

        const employees = await employeeService.getEmployees();
        const employeeRecords = employees?.data?.data;

        if (employeeRecords.length > 0) {
          const activeEmployees = employeeRecords.filter((employee) => employee.portalAccess === true);
          const formattedEmployeeRecords = getFormattedEmployeeRecords(activeEmployees);

          setEmployeeRefs(formattedEmployeeRecords);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchActiveEmployeeRefRecords();
  }, []);

  return { employeeRefs };
}
