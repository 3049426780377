import React, { useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { makeStyles } from '@material-ui/core/styles';
import MapLegend from '../../legend/MapLegend';
import useFetchVehicleTrackerRecords from '../../hooks/useFetchVehicleTrackerRecords';

import MapVehicleItem from '../MapVehicleItem';
import PropTypes from 'prop-types';
import TrackerMapComponent from './TrackerMapComponent';
import BeaconMapComponent from './BeaconMapComponent';

const useStyles = makeStyles({
  leafletInteractive: {
    strokeWidth: 5,
    stroke: '#03a9f4'
  },
  mapComponentWrapper: {
    width: '100%'
  }
});

const MapComponent = ({ deviceId, vehicleTabClicked, dateString, beaconMac, handleClickedMapItem }) => {
  const classes = useStyles();

  const latitude = -37.79869;
  const longitude = 144.89608;
  const position = [latitude, longitude];

  const [filter] = useState({
    legendColor: 'All',
    searchInput: '',
    trackerState: 'All'
  });

  const { assetTrackerRecords, loading } = useFetchVehicleTrackerRecords(filter);

  const filteredTrackerRecords = deviceId
    ? assetTrackerRecords?.filter((record) => record.deviceId === deviceId)
    : beaconMac
    ? assetTrackerRecords?.filter((record) => record.beaconMac === beaconMac)
    : assetTrackerRecords;

  if (loading) {
    return <div>Loading...</div>; // Show loading message
  }

  return (
    <div className={classes.mapComponentWrapper}>
      {deviceId ? (
        <TrackerMapComponent
          position={position}
          deviceId={deviceId}
          vehicleTabClicked={vehicleTabClicked}
          dateString={dateString}
        />
      ) : beaconMac ? (
        <BeaconMapComponent
          position={position}
          beaconInstanceID={filteredTrackerRecords[0]?.beaconInstanceID}
          vehicleTabClicked={vehicleTabClicked}
          dateString={dateString}
          beaconMac={beaconMac}
        />
      ) : (
        <MapContainer center={position} zoom={13} style={{ height: '100vh', width: '100%' }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {filteredTrackerRecords?.length > 0
            ? filteredTrackerRecords.map((vehicleTrackerItem) => (
                <MapVehicleItem
                  key={vehicleTrackerItem.deviceId || vehicleTrackerItem.beaconMac || vehicleTrackerItem.deviceName}
                  vehicleTrackerItem={vehicleTrackerItem}
                  handleClickedMapItem={handleClickedMapItem}
                />
              ))
            : null}
        </MapContainer>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ fontWeight: '600', fontSize: '1rem', marginRight: '1rem', marginTop: '1.2rem' }}>Info:</div>
        <MapLegend />
      </div>
    </div>
  );
};

export default MapComponent;

MapComponent.propTypes = {
  trackerItemClicked: PropTypes.any,
  setTrackerItemClicked: PropTypes.func,
  deviceId: PropTypes.any,
  vehicleTabClicked: PropTypes.any,
  dateString: PropTypes.any,
  beaconMac: PropTypes.any,
  handleClickedMapItem: PropTypes.func
};
