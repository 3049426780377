import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import ReactSelect from 'react-select';

import assetService from '../../../../services/AssetService';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import { Checkbox, Paper, useMediaQuery } from '@material-ui/core';

import { createOptions, formattedAssets } from '../../table/hepler';
import AssetIdChange from './AssetIdChange';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      //   marginTop: theme.spacing(1)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',

    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: 'rgb(107, 108, 114)',
    fontSize: '1rem'
  },
  bootstrapInput: {
    maxWidth: '350px',
    width: ' 100%'
  },
  paper: {
    padding: theme.spacing(3)
  },
  iconHeader: {
    display: 'flex',
    marginBottom: '1rem'
  }
}));

const AssetGeneralDialog = ({
  row,
  onChange,
  onCancelChanges,
  onApplyChanges,
  vendorRecords,
  employeeRefs,
  setRows
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [assetRecords, setaAssetRecords] = useState([]);
  const [editAssetId, setEditAssetId] = useState(false);
  const [assetLoading, setAssetLoading] = useState(false);

  // Function to format the date to YYYY-MM-DD using date-fns
  const formatDate = (dateString) => {
    return format(new Date(dateString), 'yyyy-MM-dd');
  };

  useEffect(() => {
    const fetchAssetRecords = async () => {
      try {
        setAssetLoading(true);
        const assetRecords = await assetService.getAssets();
        const assetsList = formattedAssets(assetRecords.data.data);

        setaAssetRecords(assetsList);
      } catch (e) {
        // eslint-disable-next-line no-console, no-undef
        console.log('error is:', e);
      } finally {
        setAssetLoading(false);
      }
    };

    fetchAssetRecords();
  }, []);

  const formattedEmployeeRefs =
    employeeRefs.length > 0 &&
    employeeRefs?.map((employee) => {
      return { value: employee.name, label: employee.name };
    });

  const categoryOptions = assetRecords && createOptions(assetRecords, 'category');
  const ownerOptions = formattedEmployeeRefs && [
    ...formattedEmployeeRefs,
    { value: 'BOXSHALL GROUP', label: 'BOXSHALL GROUP' }
  ];
  const operatorOptions = formattedEmployeeRefs && [
    ...formattedEmployeeRefs,
    { value: 'BOXSHALL GROUP', label: 'BOXSHALL GROUP' }
  ];

  const handleFileChange = (e) => {
    const changedFile = e.target.files[0];
    const name = 'image';
    onChange({ target: { value: changedFile, name } });
  };

  const handleSwitchChange = (e) => {
    const value = e.target.checked;
    const name = e.target.name;

    onChange({ target: { value, name } });
  };

  const handleAssetClick = () => {
    setEditAssetId(!editAssetId);
  };

  return (
    <div style={{ height: '700px' }}>
      <DialogContent>
        <Grid container spacing={3}>
          {/* name and contacts item container */}
          <Grid item container xs={12}>
            <Paper className={classes.paper}>
              <Grid item container xs={12} spacing={2} pl={isSmall ? 0 : 3}>
                {/* assetId */}
                {!editAssetId && (
                  <Grid item xs={12} sm={6}>
                    <Paper style={{ marginLeft: '1rem', padding: '0.5rem' }}>
                      <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                        AssetId
                      </InputLabel>
                      <div style={{ display: 'flex' }}>
                        <BootstrapInput
                          disabled
                          id="bootstrap"
                          name="assetId"
                          value={row.assetId || ''}
                          onChange={onChange}
                          className={classes.bootstrapInput}
                        />

                        <Button onClick={handleAssetClick} variant="outlined" style={{ marginLeft: '10px' }}>
                          Edit
                        </Button>
                      </div>
                    </Paper>
                  </Grid>
                )}

                {editAssetId && (
                  <Grid item xs={12} sm={12}>
                    <AssetIdChange
                      row={row}
                      assetRecords={assetRecords}
                      onChange={onChange}
                      handleAssetClick={handleAssetClick}
                      setRows={setRows}
                      assetLoading={assetLoading}
                    />
                  </Grid>
                )}

                {/* image */}
                <Grid item xs={12} sm={6}>
                  <Paper style={{ marginLeft: '1rem', padding: '0.5rem' }}>
                    <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                      Image
                    </InputLabel>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <img
                        src={
                          typeof row.image === 'string'
                            ? row.image
                            : // eslint-disable-next-line no-undef
                            row.image instanceof Blob || row.image instanceof File
                            ? // eslint-disable-next-line no-undef
                              URL.createObjectURL(row.image)
                            : ''
                        }
                        alt="image"
                        height={50}
                        width={100}
                        style={{ marginRight: '5px', objectFit: 'contain' }}
                        name="image"
                      />

                      <Button component="label" variant="outlined">
                        Edit
                        <input type="file" accept=".jpg, .jpeg, .png" hidden onChange={handleFileChange} name="image" />
                      </Button>
                    </div>
                  </Paper>
                </Grid>

                {/* make */}
                <Grid item xs={12} sm={4}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Make
                  </InputLabel>
                  <BootstrapInput
                    id="bootstrap"
                    name="make"
                    value={row.make || ''}
                    onChange={onChange}
                    className={classes.bootstrapInput}
                  />
                </Grid>

                {/* model */}
                <Grid item xs={12} sm={4}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Model
                  </InputLabel>
                  <BootstrapInput
                    id="bootstrap"
                    name="model"
                    value={row.model || ''}
                    onChange={onChange}
                    className={classes.bootstrapInput}
                  />
                </Grid>

                {/* description */}
                <Grid item xs={12} sm={4}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Description
                  </InputLabel>
                  <BootstrapInput
                    name="description"
                    value={row.description || ''}
                    onChange={onChange}
                    id="bootstrap"
                    className={classes.bootstrapInput}
                  />
                </Grid>

                {/* tradeSpecific */}
                <Grid item xs={12} sm={4}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Trade Specific
                  </InputLabel>
                  <BootstrapInput
                    name="tradeSpecific"
                    value={row.tradeSpecific || ''}
                    onChange={onChange}
                    id="bootstrap"
                    className={classes.bootstrapInput}
                  />
                </Grid>

                {/* supplier*/}
                <Grid item xs={12} sm={4}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Supplier
                  </InputLabel>
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select"
                    // isClearable={true}
                    defaultValue={{ value: row.supplier, label: row.supplier }}
                    isSearchable={true}
                    name="supplier"
                    options={vendorRecords}
                    onChange={(selectedOption, action) => {
                      const name = action.name,
                        value = selectedOption.value;
                      onChange({ target: { value, name } });
                    }}
                  />
                </Grid>

                {/* category */}
                <Grid item xs={12} sm={4}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Category
                  </InputLabel>
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={{ value: row.category, label: row.category }}
                    isSearchable={true}
                    // isClearable={true}
                    name="category"
                    options={categoryOptions}
                    onChange={(selectedOption, action) => {
                      const name = action.name,
                        value = selectedOption.value;
                      onChange({ target: { value, name } });
                    }}
                  />
                </Grid>

                {/* dateOfPurchase */}
                <Grid item xs={12} sm={4}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Date of purchase
                  </InputLabel>
                  <BootstrapInput
                    name="dateOfPurchase"
                    value={row.dateOfPurchase ? formatDate(row.dateOfPurchase) : ''}
                    onChange={onChange}
                    id="bootstrap"
                    className={classes.bootstrapInput}
                    type="date"
                  />
                </Grid>

                {/* owner */}
                <Grid item xs={12} sm={4}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Owner
                  </InputLabel>
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={{ value: row.owner, label: row.owner }}
                    isSearchable={true}
                    // isClearable={true}
                    name="owner"
                    options={ownerOptions}
                    onChange={(selectedOption, action) => {
                      const name = action.name,
                        value = selectedOption.value;
                      onChange({ target: { value, name } });
                    }}
                  />
                </Grid>

                {/* operator */}
                <Grid item xs={12} sm={4}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Operator
                  </InputLabel>
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={{ value: row.operator, label: row.operator }}
                    isSearchable={true}
                    // isClearable={true}
                    name="operator"
                    options={operatorOptions}
                    onChange={(selectedOption, action) => {
                      const name = action.name,
                        value = selectedOption.value;
                      onChange({ target: { value, name } });
                    }}
                  />
                </Grid>

                {/* insuranceName */}
                <Grid item xs={12} sm={4}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Insurance Name
                  </InputLabel>
                  <BootstrapInput
                    name="insuranceName"
                    value={row.insuranceName || ''}
                    onChange={onChange}
                    id="bootstrap"
                    className={classes.bootstrapInput}
                  />
                </Grid>

                {/* insurancePolicy */}
                <Grid item xs={12} sm={4}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Insurance Policy
                  </InputLabel>
                  <BootstrapInput
                    name="insurancePolicy"
                    value={row.insurancePolicy || ''}
                    onChange={onChange}
                    id="bootstrap"
                    className={classes.bootstrapInput}
                  />
                </Grid>

                {/* insuranceExpiry */}
                <Grid item xs={12} sm={4}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Insurance Expiry
                  </InputLabel>
                  <BootstrapInput
                    name="insuranceExpiry"
                    value={row.insuranceExpiry || ''}
                    onChange={onChange}
                    id="bootstrap"
                    className={classes.bootstrapInput}
                  />
                </Grid>

                {/* purchasePrice */}
                <Grid item xs={12} sm={4}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Purchase Price
                  </InputLabel>
                  <BootstrapInput
                    name="purchasePrice"
                    value={row.purchasePrice || ''}
                    onChange={onChange}
                    id="bootstrap"
                    className={classes.bootstrapInput}
                    type="number"
                  />
                </Grid>

                {/* maintenanceSchedule */}
                <Grid item xs={12} sm={4}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    MaintenanceSchedule
                  </InputLabel>
                  <BootstrapInput
                    name="maintenanceSchedule"
                    value={row.maintenanceSchedule || ''}
                    onChange={onChange}
                    id="bootstrap"
                    className={classes.bootstrapInput}
                  />
                </Grid>

                {/* disposalDate */}
                <Grid item xs={12} sm={4}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Disposal Date
                  </InputLabel>
                  <BootstrapInput
                    name="disposalDate"
                    value={row.disposalDate ? formatDate(row.disposalDate) : ''}
                    onChange={onChange}
                    id="bootstrap"
                    className={classes.bootstrapInput}
                    type="date"
                  />
                </Grid>

                {/* activeStatus */}
                <Grid item xs={12} sm={2}>
                  <InputLabel shrink htmlFor="activeStatus" className={classes.inputLabel}>
                    Active Status
                  </InputLabel>
                  <Checkbox
                    checked={row.activeStatus}
                    onChange={handleSwitchChange}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    color="primary"
                    name="activeStatus"
                  />
                </Grid>

                {/* comments */}
                <Grid item xs={12} sm={4}>
                  <InputLabel shrink htmlFor="bootstrap" className={classes.inputLabel}>
                    Comments
                  </InputLabel>
                  <BootstrapInput
                    name="comments"
                    value={row.comments || ''}
                    onChange={onChange}
                    id="bootstrap"
                    className={classes.bootstrapInput}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '0 23px 16px 0' }}>
        <Button onClick={onCancelChanges} color="secondary">
          Cancel
        </Button>
        <Button onClick={onApplyChanges} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </div>
  );
};

export default AssetGeneralDialog;

AssetGeneralDialog.propTypes = {
  row: PropTypes.any,
  onChange: PropTypes.func,
  onCancelChanges: PropTypes.func,
  onApplyChanges: PropTypes.func,
  vendorRecords: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  vendorLoading: PropTypes.bool,
  vendorError: PropTypes.string,
  employeeRefs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string
    })
  ),
  rows: PropTypes.any,
  setRows: PropTypes.func
};
