import React, { useState } from 'react';
import VehicleTrackerTopBar from './vehicleTrackerTopBar/VehicleTrackerTopBar';
import { makeStyles, Typography } from '@material-ui/core';
import VehicleTrackerBottomBar from './VehicleTrackerBottomBar/VehicleTrackerBottomBar';
import PropTypes from 'prop-types';
import useFetchVehicleTrackerRecordThirtySecond from '../hooks/useFetchVehicleTrackerRecordThirtySecond';
import useFetchVehicleTrackerRecordsFromDb from '../hooks/useFetchVehicleTrackerRecordsFromDb';
import AssetMapPagination from './AssetMapPagination';

const useStyles = makeStyles({
  leftBarWrapper: {
    border: '1px solid grey',
    borderRadius: '4px',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  }
});

const VehicleTrackerLeftBar = ({
  trackerItemClicked,
  setTrackerItemClicked,
  deviceId,
  setDeviceId,
  setVehicleTabClicked,
  clickedDate,
  setClickedDate,
  beaconMac,
  setBeaconMac,
  filter,
  setFilter,
  searchInput,
  setSearchInput,
  handleClickedMapItem
}) => {
  const classes = useStyles();

  const { vehicleTrackerRecordThirtySecond, allAssetRecords } = useFetchVehicleTrackerRecordThirtySecond(filter);

  const { assetTrackerRecordsFromDb } = useFetchVehicleTrackerRecordsFromDb(filter);

  const [assetMapPage, setAssetMapPage] = useState(0);
  const [assetMapRowsPerPage, setAssetMapRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setAssetMapPage(newPage);
    setFilter({ ...filter, skip: newPage * assetMapRowsPerPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setAssetMapRowsPerPage(parseInt(event.target.value, 10));
    setAssetMapPage(0);
  };

  return (
    <div className={classes.leftBarWrapper}>
      <VehicleTrackerTopBar
        trackerItemClicked={trackerItemClicked}
        setFilter={setFilter}
        filter={filter}
        setSearchInput={setSearchInput}
        searchInput={searchInput}
        vehicleTrackerRecords={vehicleTrackerRecordThirtySecond}
        setTrackerItemClicked={setTrackerItemClicked}
        allAssetRecords={allAssetRecords}
        setVehicleTabClicked={setVehicleTabClicked}
        deviceId={deviceId}
        setDeviceId={setDeviceId}
        clickedDate={clickedDate}
        setClickedDate={setClickedDate}
        beaconMac={beaconMac}
        setBeaconMac={setBeaconMac}
      />

      {assetTrackerRecordsFromDb && assetTrackerRecordsFromDb?.length > 0 ? (
        <>
          <VehicleTrackerBottomBar
            vehicleTrackerRecords={vehicleTrackerRecordThirtySecond}
            trackerItemClicked={trackerItemClicked}
            assetTrackerRecordsFromDb={assetTrackerRecordsFromDb}
            assetMapRowsPerPage={assetMapRowsPerPage}
            assetMapPage={assetMapPage}
            handleClickedMapItem={handleClickedMapItem}
          />

          <AssetMapPagination
            assetMapPage={assetMapPage}
            assetMapRowsPerPage={assetMapRowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            assetTrackerRecordsFromDb={assetTrackerRecordsFromDb}
          />
        </>
      ) : (
        <Typography variant="body1" style={{ padding: '1.5rem 0', textAlign: 'center' }}>
          No device
        </Typography>
      )}
    </div>
  );
};

export default VehicleTrackerLeftBar;

VehicleTrackerLeftBar.propTypes = {
  trackerItemClicked: PropTypes.any,
  setTrackerItemClicked: PropTypes.func,
  deviceId: PropTypes.any,
  setDeviceId: PropTypes.func,
  vehicleTabClicked: PropTypes.any,
  setVehicleTabClicked: PropTypes.func,
  clickedDate: PropTypes.any,
  setClickedDate: PropTypes.func,
  beaconMac: PropTypes.any,
  setBeaconMac: PropTypes.func,
  filter: PropTypes.any,
  setFilter: PropTypes.func,
  setSearchInput: PropTypes.func,
  searchInput: PropTypes.string,
  handleClickedMapItem: PropTypes.func
};
