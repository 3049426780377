import React from 'react';
import { default as ReactSelect, components } from 'react-select';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

Option.propTypes = {
  isSelected: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  innerRef: PropTypes.func,
  data: PropTypes.object
};

const ReactCheckboxSelect = ({ options, handleOptionChange, locationSelect }) => {
  const locationOption = options.filter((option) => locationSelect[option.value] === true);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1" style={{ fontSize: '14px', color: 'rgb(107, 108, 114)', paddingBottom: '.1rem' }}>
          Choose Location
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ReactSelect
          value={locationOption || ''}
          placeholder="Select location"
          options={options}
          isClearable={true}
          components={{
            Option
          }}
          onChange={(selectedOption) => {
            if (selectedOption === null) {
              handleOptionChange(null);
            } else {
              handleOptionChange(selectedOption);
            }
          }}
          // eslint-disable-next-line no-undef
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </Grid>
    </Grid>
  );
};

export default ReactCheckboxSelect;

ReactCheckboxSelect.propTypes = {
  options: PropTypes.array,
  handleOptionChange: PropTypes.func,
  optionSelected: PropTypes.any,
  locationSelect: PropTypes.any
};
