import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import PropTypes from 'prop-types';
import { format } from 'date-fns';

const DateCheckDialog = ({ date, setProceedFutureDate, setDateCheckDialogOpen, dateCheckDialogOpen }) => {
  const handleClose = () => {
    setDateCheckDialogOpen(false);
  };

  return (
    <div>
      <Dialog
        open={dateCheckDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Future Date Entry Detected'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have entered a date that is in the future: <b>{format(new Date(date), 'EEE MMM dd yyyy')}</b>. Please go
            back to modify it as you cannot add future date timesheets.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setProceedFutureDate(true);
              setDateCheckDialogOpen(false);
            }}
            color="primary"
            variant="outlined"
            style={{ fontSize: '10px' }}
          >
            Yes, it is correct - Proceed with the future date entry.
          </Button>
          <Button onClick={handleClose} color="primary" variant="contained" autoFocus style={{ fontSize: '10px' }}>
            I wll review it - Return to edit the date.
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DateCheckDialog;

DateCheckDialog.propTypes = {
  date: PropTypes.any,
  setProceedFutureDate: PropTypes.func,
  setDateCheckDialogOpen: PropTypes.func,
  dateCheckDialogOpen: PropTypes.bool
};
