import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import NavigationIcon from '@material-ui/icons/Navigation';
import NotificationsIcon from '@material-ui/icons/Notifications';
import RouteTab from './RouteTab';
import TrackerNowTab from './TrackerNowTab';
import Calendar from '../Calendar';
import BeaconNowTab from './BeaconNowTab';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import { isLessThanAWeek, withInTenMinutes } from '../../../helper/helper';
import BeaconRouteTab from './BeaconRouteTab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    padding: '0'
  },
  trackerStatus: {
    fontSize: '11px',
    margin: '1px'
  },
  label: {
    margin: '0',
    fontSize: '12px'
  },
  labelP: {
    lineHeight: '1',
    margin: '4px',
    fontSize: '12px'
  }
}));

const VehicleTrackerTabs = ({
  trackerItemClicked,
  filter,
  setFilter,
  allAssetRecords,
  clickedDate,
  deviceId,
  assetRecords,
  setClickedDate,
  statusTabValue,
  handleTrackerStatusChange,
  beaconMac
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const stoppedCarCount = allAssetRecords.filter((record) => !record.engineIgnitionStatus)?.length;
  const drivingCarCount = allAssetRecords.filter((record) => record.engineIgnitionStatus)?.length;
  const inActiveCount = allAssetRecords.filter(
    (record) => !withInTenMinutes(record.timestamp) && !isLessThanAWeek(record.timestamp)
  )?.length;

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === 0) {
      setFilter({ ...filter, trackerState: 'All' });
    }
    if (newValue === 1) {
      setFilter({ ...filter, trackerState: 'Stationary' });
    }
    if (newValue === 2) {
      setFilter({ ...filter, trackerState: 'Moving' });
    }
    if (newValue === 3) {
      setFilter({ ...filter, trackerState: 'Inactive' });
    }
  };

  return (
    <div>
      {!trackerItemClicked ? (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="simple tabs example"
            >
              <Tab
                label={
                  <div>
                    <label className={classes.label}>All</label>
                    <p className={classes.labelP}>{allAssetRecords.length}</p>
                  </div>
                }
                {...a11yProps(0)}
              />

              <Tab
                label={
                  <div>
                    <label className={classes.label}>Stationary</label>
                    <p className={classes.labelP}>{stoppedCarCount}</p>
                  </div>
                }
                {...a11yProps(1)}
              />
              <Tab
                label={
                  <div>
                    <label className={classes.label}>Moving</label>
                    <p className={classes.labelP}>{drivingCarCount}</p>
                  </div>
                }
                {...a11yProps(2)}
              />
              <Tab
                label={
                  <div>
                    <label className={classes.label}>Inactive</label>
                    <p className={classes.labelP}>{inActiveCount}</p>
                  </div>
                }
                {...a11yProps(3)}
              />
            </Tabs>
          </AppBar>
        </div>
      ) : (
        <div>
          <AppBar position="static" color="default">
            <Tabs
              value={statusTabValue}
              onChange={handleTrackerStatusChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="tracker details"
            >
              <Tab
                label={
                  <div>
                    {assetRecords.length > 0 && assetRecords[0]?.deviceId ? (
                      <DriveEtaIcon />
                    ) : assetRecords.length > 0 && assetRecords[0]?.beaconMac ? (
                      <BluetoothIcon />
                    ) : null}

                    <p className={classes.trackerStatus}>Now</p>
                  </div>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <div>
                    <NavigationIcon />
                    <p className={classes.trackerStatus}>Routes</p>
                  </div>
                }
                {...a11yProps(1)}
              />
              <Tab
                label={
                  <div>
                    <NotificationsIcon />
                    <p className={classes.trackerStatus}>Notifications</p>
                  </div>
                }
                {...a11yProps(2)}
              />
            </Tabs>
          </AppBar>

          <TabPanel value={statusTabValue} index={0}>
            {assetRecords.length > 0 && assetRecords[0]?.deviceId ? (
              <div>
                <TrackerNowTab assetRecords={assetRecords} />
              </div>
            ) : assetRecords.length > 0 && assetRecords[0]?.beaconMac ? (
              <div>
                <BeaconNowTab assetRecord={assetRecords} />
              </div>
            ) : null}
          </TabPanel>
          <TabPanel value={statusTabValue} index={1}>
            <div>
              <div style={{ marginBottom: '1rem' }}>
                <Calendar clickedDate={clickedDate} setClickedDate={setClickedDate} />
              </div>
              {deviceId ? (
                <RouteTab deviceId={deviceId} dateString={clickedDate} />
              ) : beaconMac ? (
                <BeaconRouteTab beaconInstanceID={assetRecords[0]?.beaconInstanceID} dateString={clickedDate} />
              ) : null}
            </div>
          </TabPanel>
          {/* <TabPanel value={value} index={2}>
            Item Three
          </TabPanel> */}
        </div>
      )}
    </div>
  );
};

export default VehicleTrackerTabs;

VehicleTrackerTabs.propTypes = {
  trackerItemClicked: PropTypes.bool,
  setFilter: PropTypes.func,
  filter: PropTypes.shape({
    legendColor: PropTypes.string,
    searchInput: PropTypes.string
  }),
  allAssetRecords: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      long: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      deviceName: PropTypes.string,
      timestamp: PropTypes.number
    })
  ),
  clickedDate: PropTypes.any,
  deviceId: PropTypes.any,
  assetRecords: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string,
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      long: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      timestamp: PropTypes.number,
      engineIgnitionStatus: PropTypes.bool,
      vehicleMileage: PropTypes.number,
      sleepModeEnum: PropTypes.number,
      protocolId: PropTypes.number,
      positionAltitude: PropTypes.number,
      positionDirection: PropTypes.number,
      positionSpeed: PropTypes.number,
      gsmSignalLevel: PropTypes.number, // number but its the percentage
      batteryCurrent: PropTypes.number, //its in volt
      batteryVoltage: PropTypes.number,
      externalPowersourceVoltage: PropTypes.number,
      deviceId: PropTypes.any,
      beaconMac: PropTypes.any,
      beaconInstanceID: PropTypes.any
    })
  ),
  setClickedDate: PropTypes.func,
  statusTabValue: PropTypes.number,
  handleTrackerStatusChange: PropTypes.func,
  beaconMac: PropTypes.any
};
