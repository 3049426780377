import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Polyline, FeatureGroup, Marker, Tooltip } from 'react-leaflet';
import { getMarkerIcon } from '../MarkerIcon';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { parseISO, startOfDay, endOfDay, getUnixTime } from 'date-fns';
import vehicleTrackerService from '../../../../services/VehicleTrackerService';

const useStyles = makeStyles({
  leafletInteractive: {
    strokeWidth: 5,
    stroke: '#03a9f4'
  },
  mapComponentWrapper: {
    width: '100%'
  }
});

const TrackerMapComponent = ({ position, deviceId, vehicleTabClicked, dateString }) => {
  const classes = useStyles();

  const [polylineRecord, setPolylineRecord] = useState([]);
  const [routeHistory, setRouteHistory] = useState([]);

  useEffect(() => {
    const fetchRouteHistory = async () => {
      try {
        // Parse the date string
        const date = parseISO(dateString);
        // Get the start and end of the day
        const fromTimestamp = getUnixTime(startOfDay(date)); // 12:00 AM timestamp
        const toTimestamp = getUnixTime(endOfDay(date)); // 11:59 PM timestamp

        const vehiclePayload = {
          deviceId: deviceId,
          fromTimestamp: fromTimestamp,
          toTimestamp: toTimestamp
        };

        if (deviceId && vehicleTabClicked === 'route' && dateString) {
          const routeHistory = await vehicleTrackerService.getTelemetryRouteHistory(vehiclePayload);

          const routeRecord = routeHistory?.data?.data;
          setRouteHistory(routeRecord);

          const routePolyLine = [];
          if (routeRecord?.length > 0) {
            routeRecord.map((record) => routePolyLine.push(record.records));
          }

          setPolylineRecord(routePolyLine);
        }
      } catch (error) {
        // eslint-disable-next-line no-console, no-undef
        console.log('error is:', error);
      }
    };
    fetchRouteHistory();
  }, [dateString, deviceId, vehicleTabClicked]);
  return (
    <div>
      <MapContainer center={position} zoom={11} style={{ height: '100vh', width: '100%' }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {polylineRecord.map((records, index) => {
          return (
            <FeatureGroup key={index}>
              {routeHistory[index]?.positions?.map((position, index) => {
                if (index === 0) {
                  return (
                    <Marker
                      key={index}
                      position={[position.latitude, position.longitude]}
                      icon={getMarkerIcon('green')}
                    >
                      <Tooltip direction="right" offset={[25, 20]} opacity={1} permanent>
                        <span>Start</span>
                      </Tooltip>
                    </Marker>
                  );
                } else {
                  return (
                    <Marker key={index} position={[position.latitude, position.longitude]} icon={getMarkerIcon('red')}>
                      <Tooltip direction="right" offset={[25, 20]} opacity={1} permanent>
                        <span>End</span>
                      </Tooltip>
                    </Marker>
                  );
                }
              })}

              <Polyline positions={records} color="blue" className={classes.leafletInteractive} />
            </FeatureGroup>
          );
        })}
      </MapContainer>
    </div>
  );
};

export default TrackerMapComponent;

TrackerMapComponent.propTypes = {
  position: PropTypes.any,
  deviceId: PropTypes.any,
  vehicleTabClicked: PropTypes.any,
  dateString: PropTypes.any
};
