import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../shared/inputs/Input';
import ReactSelect from 'react-select';

import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  InputBase,
  InputLabel,
  makeStyles,
  Paper,
  useMediaQuery,
  useTheme,
  withStyles
} from '@material-ui/core';
import assetService from '../../../../services/AssetService';
import toast from 'react-hot-toast';
import { formattedAssets, isAssetTokenValid } from '../../table/hepler';
import { AssetIcon } from '../../assetIcon/AssetIcon';
import { format } from 'date-fns';
import ReactCheckboxSelect from './ReactCheckboxSelect';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      //   marginTop: theme.spacing(1)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',

    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2]
  },
  input: {
    width: '100%',
    marginTop: theme.spacing(1)
  }
}));

const AssetLocation = ({ row, onCancelChanges, setRows, rows }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [tokenMacInput, setTokenMacInput] = useState({
    beaconTokenMac: row?.beaconTokenMac ? row?.tokenMac : '',
    trackerTokenMac: row?.trackerTokenMac ? row?.tokenMac : '',
    location: row?.location ? row?.location : '',
    locationDate: row?.locationDate ? row?.locationDate : '',
    linkedAssetId: row?.linkedAssetId ? row?.linkedAssetId : ''
  });

  const [locationSelect, setLocationSelect] = useState({
    fixedLocation: row?.fixedLocation || false,
    beaconTokenMac: row?.beaconTokenMac || false,
    trackerTokenMac: row?.trackerTokenMac || false,
    linkedAsset: row?.linkedAsset || false
  });

  const [mapIcon, setMapIcon] = useState(row?.mapIcon || '');
  const assetLocationOptions = [
    {
      value: 'fixedLocation',
      label: 'Fixed Location'
    },
    {
      value: 'beaconTokenMac',
      label: 'Beacon Token Mac'
    },
    {
      value: 'trackerTokenMac',
      label: 'GPS Token Mac'
    },
    { value: 'linkedAsset', label: 'Link Asset' }
  ];
  const [assetOptions, setAssetOptions] = useState([]);

  useEffect(() => {
    const fetchAssetRecords = async () => {
      try {
        const assetRecords = await assetService.getAssets();

        const assetsList = formattedAssets(assetRecords.data.data);

        const assetOptionRecord = assetsList.map((asset) => {
          return {
            value: asset.assetId,
            label: `AssetId: ${asset.assetId}, Operator:${asset.operator}, Description:${asset.description}`
          };
        });

        setAssetOptions(assetOptionRecord);
      } catch (e) {
        // eslint-disable-next-line no-console, no-undef
        console.log('error is:', e);
      }
    };

    fetchAssetRecords();
  }, []);

  // Function to format the date to YYYY-MM-DD using date-fns
  const formatDate = (dateString) => {
    return format(new Date(dateString), 'yyyy-MM-dd');
  };

  const handleLocationTabInput = (e, name) => {
    if (name === 'beaconTokenMac') {
      setTokenMacInput({
        ...tokenMacInput,
        [name]: e.target.value,
        ['trackerTokenMac']: '',
        ['location']: '',
        ['locationDate']: ''
      });
    }
    if (name === 'trackerTokenMac') {
      setTokenMacInput({
        ...tokenMacInput,
        [name]: e.target.value,
        ['beaconTokenMac']: '',
        ['location']: '',
        ['locationDate']: ''
      });
    }
    if (name === 'location' || name === 'locationDate') {
      setTokenMacInput({ ...tokenMacInput, [name]: e.target.value, ['beaconTokenMac']: '', ['trackerTokenMac']: '' });
    }
  };

  //map icon
  const handleSelectedIcon = (selectedOption) => {
    setMapIcon(selectedOption?.value || null);
  };

  //location select
  const handleOptionChange = (selected) => {
    if (selected === null) {
      // Reset state if selected is null
      setLocationSelect({
        fixedLocation: false,
        beaconTokenMac: false,
        trackerTokenMac: false
      });
      setTokenMacInput({
        location: '',
        trackerTokenMac: '',
        beaconTokenMac: '',
        locationDate: ''
      });
    }
    if (selected?.value === 'fixedLocation') {
      setLocationSelect({
        ...locationSelect,
        [selected.value]: true,
        ['trackerTokenMac']: false,
        ['beaconTokenMac']: false,
        ['linkedAsset']: false
      });
      setTokenMacInput({ ...tokenMacInput, ['trackerTokenMac']: '', ['beaconTokenMac']: '', ['linkedAsset']: '' });
    }
    if (selected?.value === 'beaconTokenMac') {
      setLocationSelect({
        ...locationSelect,
        [selected.value]: true,
        ['trackerTokenMac']: false,
        ['fixedLocation']: false,
        ['linkedAsset']: false
      });
      setTokenMacInput({
        ...tokenMacInput,
        ['location']: '',
        ['trackerTokenMac']: '',
        ['locationDate']: '',
        ['linkedAsset']: ''
      });
    }
    if (selected?.value === 'trackerTokenMac') {
      setLocationSelect({
        ...locationSelect,
        [selected.value]: true,
        ['beaconTokenMac']: false,
        ['fixedLocation']: false,
        ['linkedAsset']: false
      });
      setTokenMacInput({
        ...tokenMacInput,
        ['beaconTokenMac']: '',
        ['locationDate']: '',
        ['location']: '',
        ['linkedAsset']: ''
      });
    }
    if (selected?.value === 'linkedAsset') {
      setLocationSelect({
        ...locationSelect,
        [selected.value]: true,
        ['beaconTokenMac']: false,
        ['fixedLocation']: false,
        ['trackerTokenMac']: false
      });
      setTokenMacInput({
        ...tokenMacInput,
        ['beaconTokenMac']: '',
        ['trackerTokenMac']: '',
        ['locationDate']: '',
        ['location']: ''
      });
    }
  };

  const handleLinkedAsset = (selectedOption) => {
    if (selectedOption) {
      setTokenMacInput({
        ...tokenMacInput,
        ['linkedAssetId']: selectedOption?.value,
        ['beaconTokenMac']: '',
        ['trackerTokenMac']: '',
        ['location']: '',
        ['locationDate']: ''
      });
    }
  };

  const handleAssetUpdate = () => {
    if (!row?.assetId) {
      return toast.error('No assetId found to update location information');
    }

    let assetPayload;

    //check if any location is selected it's input are not empty
    const assetLocation = isAssetTokenValid(locationSelect, tokenMacInput);
    if (!assetLocation) {
      return toast.error('Please fill selected location input.');
    }

    if (mapIcon) {
      assetPayload = {
        assetId: row?.assetId,
        fixedLocation: locationSelect.fixedLocation,
        beaconTokenMac: locationSelect.beaconTokenMac,
        trackerTokenMac: locationSelect.trackerTokenMac,
        tokenMac: tokenMacInput.beaconTokenMac || tokenMacInput.trackerTokenMac || '',
        location: locationSelect.fixedLocation ? tokenMacInput?.location : '',
        locationDate: locationSelect.fixedLocation ? tokenMacInput?.locationDate : '',
        linkedAsset: locationSelect.linkedAsset,
        linkedAssetId: locationSelect.linkedAsset ? tokenMacInput?.linkedAssetId : '',
        mapIcon: mapIcon
      };
    } else {
      assetPayload = {
        assetId: row?.assetId,
        fixedLocation: locationSelect.fixedLocation,
        beaconTokenMac: locationSelect.beaconTokenMac,
        trackerTokenMac: locationSelect.trackerTokenMac,
        tokenMac: tokenMacInput.beaconTokenMac || tokenMacInput.trackerTokenMac || '',
        location: locationSelect.fixedLocation ? tokenMacInput?.location : '',
        locationDate: locationSelect.fixedLocation ? tokenMacInput?.locationDate : '',
        linkedAsset: locationSelect.linkedAsset,
        linkedAssetId: locationSelect.linkedAsset ? tokenMacInput?.linkedAssetId : ''
      };
    }

    console.log('assetPayload', assetPayload);

    assetService
      .updateAssetTokenMac(assetPayload)
      .then((data) => {
        const assetRecord = data?.data?.data;
        const assetArray = [];
        assetArray.push(assetRecord);
        const formattedAsset = formattedAssets(assetArray)[0];

        const updatedRows = rows.map((row) => {
          if (row.id === formattedAsset.id) {
            return { ...row, ...formattedAsset };
          }
          return row;
        });

        setRows(updatedRows);
        return toast.success("Successfully updated asset's location data");
      })
      .catch((error) => {
        return toast.error(
          error?.response?.data?.message || 'Could not find assetId to update the location of the asset'
        );
      });
  };

  const assetOption = assetOptions?.filter((option) => option.value === tokenMacInput?.linkedAssetId);

  return (
    <div style={{ height: '700px' }}>
      <DialogContent>
        <Paper className={classes.paper}>
          <Grid container spacing={2} style={{ padding: '1rem' }}>
            <Grid container spacing={3}>
              {!isSmall && (
                <Grid item xs={12} sm={6}>
                  <ReactCheckboxSelect
                    options={assetLocationOptions}
                    locationSelect={locationSelect}
                    handleOptionChange={handleOptionChange}
                    tokenMacInput={tokenMacInput}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <AssetIcon handleSelectedIcon={handleSelectedIcon} mapIcon={mapIcon} />
              </Grid>
            </Grid>
            {isSmall && (
              <Grid item xs={12} sm={6}>
                <ReactCheckboxSelect
                  options={assetLocationOptions}
                  locationSelect={locationSelect}
                  handleOptionChange={handleOptionChange}
                  tokenMacInput={tokenMacInput}
                />
              </Grid>
            )}
            {locationSelect.fixedLocation ? (
              <Grid item xs={12} style={{ marginTop: '1rem' }}>
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Input
                      className={classes.input}
                      name="Location"
                      value={tokenMacInput?.location ? tokenMacInput?.location : ''}
                      onChange={(e) => handleLocationTabInput(e, 'location')}
                      placeholder="Enter Fixed Location"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <InputLabel shrink htmlFor="locationDate" className={classes.inputLabel}>
                      Location Date
                    </InputLabel>
                    <BootstrapInput
                      name="locationDate"
                      value={tokenMacInput?.locationDate ? formatDate(tokenMacInput?.locationDate) : ''}
                      onChange={(e) => handleLocationTabInput(e, 'locationDate')}
                      id="locationDate"
                      className={classes.input}
                      type="date"
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : locationSelect.beaconTokenMac ? (
              <Grid item xs={12}>
                <Input
                  className={classes.input}
                  name="Beacon Token Mac"
                  value={tokenMacInput.beaconTokenMac ? tokenMacInput.beaconTokenMac : ''}
                  onChange={(e) => handleLocationTabInput(e, 'beaconTokenMac')}
                  placeholder="Enter Beacon Token Mac"
                />
              </Grid>
            ) : locationSelect.trackerTokenMac ? (
              <Grid item xs={12}>
                <Input
                  className={classes.input}
                  name="GPS Token Mac"
                  value={tokenMacInput.trackerTokenMac ? tokenMacInput.trackerTokenMac : ''}
                  onChange={(e) => handleLocationTabInput(e, 'trackerTokenMac')}
                  placeholder="Enter Tracker Token Mac"
                />
              </Grid>
            ) : locationSelect.linkedAsset ? (
              <Grid item xs={12}>
                {assetOptions.length > 0 ? (
                  <ReactSelect
                    value={assetOption || ''}
                    defaultValue=""
                    placeholder="Select location"
                    options={assetOptions}
                    isClearable={true}
                    onChange={(selectedOption) => {
                      if (selectedOption === null) {
                        handleLinkedAsset(null);
                      } else {
                        handleLinkedAsset(selectedOption);
                      }
                    }}
                    // eslint-disable-next-line no-undef
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Grid>
            ) : null}
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelChanges} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleAssetUpdate} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </div>
  );
};

export default AssetLocation;

AssetLocation.propTypes = {
  row: PropTypes.any,
  onChange: PropTypes.func,
  onCancelChanges: PropTypes.func,
  onApplyChanges: PropTypes.func,
  rows: PropTypes.any,
  setRows: PropTypes.func
};
