import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import DriveEtaIcon from '@material-ui/icons/DriveEta';

import PropTypes from 'prop-types';
import ListItemDetails from './ListItemDetails';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  listWrapper: {
    height: '893px',
    overflowY: 'auto'
  }
});

const VehicleTrackerBottomBar = ({
  vehicleTrackerRecords,
  trackerItemClicked,
  assetTrackerRecordsFromDb,
  assetMapRowsPerPage,
  assetMapPage,
  handleClickedMapItem
}) => {
  const classes = useStyles();

  // Slice data for current page
  const paginatedRecords = assetTrackerRecordsFromDb?.slice(
    assetMapPage * assetMapRowsPerPage,
    assetMapPage * assetMapRowsPerPage + assetMapRowsPerPage
  );

  return (
    <div className={classes.listWrapper}>
      {!trackerItemClicked && (
        <List component="nav" aria-label="main vehicle tracker folders">
          {assetTrackerRecordsFromDb.length > 0 && paginatedRecords.length > 0 ? (
            paginatedRecords?.map((record, index) => {
              return (
                <div key={record.deviceName + index}>
                  <Divider />
                  <ListItem
                    button
                    onClick={() => handleClickedMapItem(record.deviceName, record.deviceId, record.beaconMac)}
                  >
                    <ListItemIcon>{record.beaconMac ? <BluetoothIcon /> : <DriveEtaIcon />}</ListItemIcon>
                    <ListItemDetails record={record} vehicleTrackerRecords={vehicleTrackerRecords} />
                  </ListItem>
                  <Divider />
                </div>
              );
            })
          ) : (
            <>
              <Divider />
              <p>No device found</p>
              <Divider />
            </>
          )}
        </List>
      )}
    </div>
  );
};

export default VehicleTrackerBottomBar;

VehicleTrackerBottomBar.propTypes = {
  vehicleTrackerRecords: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string,
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      long: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      timestamp: PropTypes.number,
      engineIgnitionStatus: PropTypes.bool,
      vehicleMileage: PropTypes.number,
      sleepModeEnum: PropTypes.number,
      protocolId: PropTypes.number,
      positionAltitude: PropTypes.number,
      positionDirection: PropTypes.number,
      positionSpeed: PropTypes.number,
      gsmSignalLevel: PropTypes.number, // number but its the percentage
      batteryCurrent: PropTypes.number, //its in volt
      batteryVoltage: PropTypes.number,
      externalPowersourceVoltage: PropTypes.number
    })
  ),
  assetTrackerRecordsFromDb: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string,
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      long: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      timestamp: PropTypes.number,
      engineIgnitionStatus: PropTypes.bool,
      vehicleMileage: PropTypes.number,
      sleepModeEnum: PropTypes.number,
      protocolId: PropTypes.number,
      positionAltitude: PropTypes.number,
      positionDirection: PropTypes.number,
      positionSpeed: PropTypes.number,
      gsmSignalLevel: PropTypes.number, // number but its the percentage
      batteryCurrent: PropTypes.number, //its in volt
      batteryVoltage: PropTypes.number,
      externalPowersourceVoltage: PropTypes.number
    })
  ),
  setTrackerItemClicked: PropTypes.func,
  setFilter: PropTypes.func,
  filter: PropTypes.shape({
    legendColor: PropTypes.string,
    searchInput: PropTypes.string
  }),
  setSearchInput: PropTypes.func,
  trackerItemClicked: PropTypes.bool,
  setDeviceId: PropTypes.func,
  assetMapRowsPerPage: PropTypes.number,
  assetMapPage: PropTypes.number,
  setBeaconInstanceID: PropTypes.func,
  handleClickedMapItem: PropTypes.func
};
