import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { InputLabel, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { AssetIcon } from '../assetIcon/AssetIcon';
import { Controller } from 'react-hook-form';
import InputBase from '@material-ui/core/InputBase';
import PropTypes from 'prop-types';
import ReactCheckboxSelect from '../tabs/AssetLocation/ReactCheckboxSelect';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      // marginTop: theme.spacing(1)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderColor: theme.palette.primary.main
    }
  }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2]
  },
  inputLabel: {
    color: 'rgb(107, 108, 114)',
    fontSize: '1rem',
    maxWidth: '500px',
    width: '100%'
  },
  bootstrapInput: {
    maxWidth: '350px',
    width: '100%'
  }
}));

function AddAssetLocation({
  control,
  errors,
  beaconTokenMacClicked,
  trackerTokenMacClicked,
  mapIcon,
  handleSelectedIcon,
  setValue,
  fixedLocationClicked,
  locationSelect,
  setLocationSelect,
  assetLocationOptions
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const todayDate = new Date().toISOString().split('T')[0];

  //location select
  const handleOptionChange = (selected) => {
    if (selected === null) {
      // Reset state if selected is null
      setLocationSelect({
        fixedLocation: false,
        beaconTokenMac: false,
        trackerTokenMac: false
      });
      setValue('trackerTokenMacInput', '');
      setValue('beaconTokenMacInput', '');
      setValue('location', '');
      setValue('locationDate', '');
    }
    if (selected?.value === 'fixedLocation') {
      setValue('fixedLocation', true);
      setValue('trackerTokenMac', false);
      setValue('trackerTokenMacInput', '');
      setValue('beaconTokenMac', false);
      setValue('beaconTokenMacInput', '');
      setLocationSelect({
        ...locationSelect,
        [selected.value]: true,
        ['trackerTokenMac']: false,
        ['beaconTokenMac']: false
      });
    }
    if (selected?.value === 'beaconTokenMac') {
      setValue('beaconTokenMac', true);
      setValue('fixedLocation', false);
      setValue('location', '');
      setValue('locationDate', '');
      setValue('trackerTokenMacInput', '');
      setValue('trackerTokenMac', false);
      setLocationSelect({
        ...locationSelect,
        [selected.value]: true,
        ['trackerTokenMac']: false,
        ['fixedLocation']: false
      });
    }
    if (selected?.value === 'trackerTokenMac') {
      setValue('trackerTokenMac', true);
      setValue('fixedLocation', false);
      setValue('location', '');
      setValue('locationDate', '');
      setValue('beaconTokenMac', false);
      setValue('beaconTokenMacInput', '');
      setLocationSelect({
        ...locationSelect,
        [selected.value]: true,
        ['beaconTokenMac']: false,
        ['fixedLocation']: false
      });
    }
  };

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid item container spacing={3} style={{ display: 'flex' }}>
            {!isSmall && (
              <Grid item xs={12} sm={6}>
                <ReactCheckboxSelect
                  options={assetLocationOptions}
                  locationSelect={locationSelect}
                  handleOptionChange={handleOptionChange}
                />
              </Grid>
            )}

            {/* asset icon */}
            <Grid item xs={12} sm={6}>
              <AssetIcon handleSelectedIcon={handleSelectedIcon} mapIcon={mapIcon} />
            </Grid>
          </Grid>
        </Grid>

        {isSmall && (
          <Grid item xs={12} sm={6}>
            <ReactCheckboxSelect
              options={assetLocationOptions}
              locationSelect={locationSelect}
              handleOptionChange={handleOptionChange}
            />
          </Grid>
        )}

        {fixedLocationClicked ? (
          <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={4}>
                <InputLabel shrink htmlFor="location" className={classes.inputLabel}>
                  Location
                </InputLabel>
                <Controller
                  name="location"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      <BootstrapInput {...field} className={classes.bootstrapInput} />
                      {errors.location && (
                        <p
                          style={{
                            color: 'red',
                            marginTop: '10px',
                            fontSize: '0.8rem',
                            lineHeight: '1',
                            marginBottom: '0px',
                            paddingBottom: '0px'
                          }}
                        >
                          {errors?.location?.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel shrink htmlFor="locationDate" className={classes.inputLabel}>
                  Location Date
                </InputLabel>
                <Controller
                  name="locationDate"
                  control={control}
                  defaultValue={todayDate}
                  render={({ field }) => (
                    <>
                      <BootstrapInput {...field} className={classes.bootstrapInput} type="date" />
                      {errors.locationDate && (
                        <p
                          style={{
                            color: 'red',
                            marginTop: '10px',
                            fontSize: '0.8rem',
                            lineHeight: '1',
                            marginBottom: '0px',
                            paddingBottom: '0px'
                          }}
                        >
                          {errors?.locationDate?.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : beaconTokenMacClicked ? (
          <Grid item xs={12}>
            <InputLabel shrink htmlFor="locationDate" className={classes.inputLabel}>
              Beacon Token Mac
            </InputLabel>
            <Controller
              name="beaconTokenMacInput"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <BootstrapInput {...field} className={classes.bootstrapInput} />
                  {errors.beaconTokenMacInput && (
                    <p
                      style={{
                        color: 'red',
                        marginTop: '10px',
                        fontSize: '0.8rem',
                        lineHeight: '1',
                        marginBottom: '0px',
                        paddingBottom: '0px'
                      }}
                    >
                      {errors?.beaconTokenMacInput?.message}
                    </p>
                  )}
                </>
              )}
            />
          </Grid>
        ) : trackerTokenMacClicked ? (
          <Grid item xs={12}>
            <InputLabel shrink htmlFor="locationDate" className={classes.inputLabel}>
              GPS Token Mac
            </InputLabel>
            <Controller
              name="trackerTokenMacInput"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <BootstrapInput {...field} className={classes.bootstrapInput} />
                  {errors.trackerTokenMacInput && (
                    <p
                      style={{
                        color: 'red',
                        marginTop: '10px',
                        fontSize: '0.8rem',
                        lineHeight: '1',
                        marginBottom: '0px',
                        paddingBottom: '0px'
                      }}
                    >
                      {errors?.trackerTokenMacInput?.message}
                    </p>
                  )}
                </>
              )}
            />
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
}

export default AddAssetLocation;

AddAssetLocation.propTypes = {
  control: PropTypes.any,
  errors: PropTypes.any,
  beaconTokenMacClicked: PropTypes.any,
  trackerTokenMacClicked: PropTypes.any,
  mapIcon: PropTypes.any,
  setMapIcon: PropTypes.any,
  handleSelectedIcon: PropTypes.any,
  setValue: PropTypes.any,
  fixedLocationClicked: PropTypes.any,
  locationSelect: PropTypes.any,
  setLocationSelect: PropTypes.any,
  assetLocationOptions: PropTypes.any
};
