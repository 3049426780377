import { useEffect, useState } from 'react';
import { getFormattedAssetWithTokenMac, isLessThanAWeek, withInTenMinutes } from '../helper/helper';
import assetService from '../../../services/AssetService';

export default function useFetchVehicleTrackerRecordsFromDb(filter) {
  const [assetTrackerRecordsFromDb, setAssetTrackerRecordsFromDb] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVehicleTrackerRecords = async () => {
      setLoading(true);
      try {
        //asset records with tokenMac
        const assetResponse = await assetService.getAssetWithTokenMac();
        const assetWithTokenMac = assetResponse?.data?.data;
        const assetFormattedRecords = getFormattedAssetWithTokenMac(assetWithTokenMac);

        // Apply filters
        const filteredRecords = assetFormattedRecords?.filter((record) => {
          // if (!record.timestamp) return false;

          if (filter.legendColor === 'All') return true;
          if (filter.legendColor === 'Green' && withInTenMinutes(record.timestamp)) return true;
          if (filter.legendColor === 'Yellow' && isLessThanAWeek(record.timestamp)) return true;
          if (filter.legendColor === 'Red' && !withInTenMinutes(record.timestamp) && !isLessThanAWeek(record.timestamp))
            return true;

          return false;
        });

        const searchRecord = filterSearchRecord(filteredRecords, filter.searchInput);

        const filterAsset = filterAssetByCategory(searchRecord, filter.filterAssetBy);
        // console.log('filterAsset', filterAsset);

        const searchedRecords = filterAsset.filter((record) => {
          if (filter.trackerState === 'All') return true;
          if (filter.trackerState === 'Stationary' && !record.engineIgnitionStatus) return true;
          if (filter.trackerState === 'Moving' && record.engineIgnitionStatus) return true;
          if (filter.trackerState === 'Inactive' && !isLessThanAWeek(record?.timestamp)) return true;

          return false;
        });

        setAssetTrackerRecordsFromDb(searchedRecords);
      } catch (error) {
        setError('Failed to fetch vehicle tracker records');
        // eslint-disable-next-line no-console, no-undef
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicleTrackerRecords();
  }, [filter.filterAssetBy, filter.legendColor, filter.searchInput, filter.trackerState]);

  return { assetTrackerRecordsFromDb, loading, error, setAssetTrackerRecordsFromDb };
}

function filterSearchRecord(records, query) {
  return records.filter((record) => record.deviceName?.toLowerCase().includes(query.toLowerCase()));
}

function filterAssetByCategory(records, queryObject) {
  // Create a mapping of the keys to their respective lists
  const result = {};
  if (queryObject) {
    Object.keys(queryObject).forEach((key) => {
      result[`${key}List`] = queryObject[key].map((item) => item.value.toLowerCase());
    });

    // Access the output
    const categoryList = result.categoryList || [];
    const operatorList = result.operatorList || [];
    const ownerList = result.ownerList || [];

    // Filter records
    const filteredRecords = records?.filter((record) => {
      const matchesCategory = categoryList?.length === 0 || categoryList?.includes(record?.category?.toLowerCase());
      const matchesOperator = operatorList?.length === 0 || operatorList?.includes(record?.operator?.toLowerCase());
      const matchesOwner = ownerList?.length === 0 || ownerList?.includes(record?.owner?.toLowerCase());

      return matchesCategory && matchesOperator && matchesOwner;
    });

    return filteredRecords;
  }
  return records;
}
