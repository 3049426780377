import React, { useState } from 'react';
import Search from './Search';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import VehicleTrackerTabs from './tabs/VehicleTrackerTabs';
import FilterAsset from './FilterAsset';
import AssetFilterSelect from './AssetFilterSelect';

const useStyles = makeStyles((theme) => ({
  topBarWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  }
}));

const VehicleTrackerTopBar = ({
  trackerItemClicked,
  setFilter,
  filter,
  setSearchInput,
  searchInput,
  vehicleTrackerRecords,
  setTrackerItemClicked,
  allAssetRecords,
  setVehicleTabClicked,
  deviceId,
  setDeviceId,
  clickedDate,
  setClickedDate,
  beaconMac,
  setBeaconMac
}) => {
  const classes = useStyles();

  const [statusTabValue, setStatusValue] = useState(0);
  const [filterListOpen, setFilterListOpen] = useState(false);

  let todayDate = new Date();
  todayDate = todayDate.toLocaleDateString();
  todayDate = todayDate.split('/').reverse().join('-');

  const handleTrackerStatusChange = (event, newValue) => {
    setStatusValue(newValue);
    if (newValue === 0) {
      setVehicleTabClicked('now');
      setClickedDate(todayDate);
    }
    if (newValue === 1) {
      setVehicleTabClicked('route');
    }
    if (newValue === 2) {
      setVehicleTabClicked('notification');
      setClickedDate(todayDate);
    }
  };

  const handleFilterList = () => {
    setFilterListOpen(!filterListOpen);
  };

  return (
    <div className={classes.topBarWrapper}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Search
          setFilter={setFilter}
          filter={filter}
          setSearchInput={setSearchInput}
          searchInput={searchInput}
          trackerItemClicked={trackerItemClicked}
          setTrackerItemClicked={setTrackerItemClicked}
          setVehicleTabClicked={setVehicleTabClicked}
          setStatusValue={setStatusValue}
          setDeviceId={setDeviceId}
          setClickedDate={setClickedDate}
          setBeaconMac={setBeaconMac}
        />
        <FilterAsset handleFilterList={handleFilterList} />
      </div>
      {filterListOpen && <AssetFilterSelect setFilter={setFilter} />}
      <div>
        <VehicleTrackerTabs
          setFilter={setFilter}
          filter={filter}
          trackerItemClicked={trackerItemClicked}
          allAssetRecords={allAssetRecords}
          setVehicleTabClicked={setVehicleTabClicked}
          clickedDate={clickedDate}
          deviceId={deviceId}
          VehicleTrackerTabs={VehicleTrackerTabs}
          assetRecords={vehicleTrackerRecords}
          setClickedDate={setClickedDate}
          statusTabValue={statusTabValue}
          handleTrackerStatusChange={handleTrackerStatusChange}
          beaconMac={beaconMac}
        />
      </div>
    </div>
  );
};

export default VehicleTrackerTopBar;

VehicleTrackerTopBar.propTypes = {
  trackerItemClicked: PropTypes.bool,
  setFilter: PropTypes.func,
  filter: PropTypes.shape({
    legendColor: PropTypes.string,
    searchInput: PropTypes.string
  }),
  setSearchInput: PropTypes.func,
  searchInput: PropTypes.string,
  vehicleTrackerRecords: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string,
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      long: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      timestamp: PropTypes.number,
      engineIgnitionStatus: PropTypes.bool,
      vehicleMileage: PropTypes.number,
      sleepModeEnum: PropTypes.number,
      protocolId: PropTypes.number,
      positionAltitude: PropTypes.number,
      positionDirection: PropTypes.number,
      positionSpeed: PropTypes.number,
      gsmSignalLevel: PropTypes.number, // number but its the percentage
      batteryCurrent: PropTypes.number, //its in volt
      batteryVoltage: PropTypes.number,
      externalPowersourceVoltage: PropTypes.number
    })
  ),
  allAssetRecords: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      long: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      deviceName: PropTypes.string,
      timestamp: PropTypes.number
    })
  ),
  setTrackerItemClicked: PropTypes.func,
  setVehicleTabClicked: PropTypes.func,
  deviceId: PropTypes.any,
  setDeviceId: PropTypes.func,
  clickedDate: PropTypes.any,
  setClickedDate: PropTypes.func,
  vehicleTabClicked: PropTypes.any,
  beaconMac: PropTypes.any,
  setBeaconMac: PropTypes.func
};
