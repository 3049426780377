import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const useStyles = makeStyles({
  searchWrapper: {
    display: 'flex',
    flex: '1',
    border: '1px solid grey',
    borderRadius: '4px',
    padding: '4px',
    justifyContent: 'space-between',
    margin: '1rem 1rem 0 1rem'
  },
  textField: {
    width: '100%'
  },
  searchDiv: {
    marginRight: '10px',
    marginTop: '4px'
  },
  backIcon: {
    marginTop: '4px',
    marginRight: '2px',
    cursor: 'pointer',
    textAlign: 'center'
  }
});

const Search = ({
  setFilter,
  filter,
  searchInput,
  setSearchInput,
  trackerItemClicked,
  setTrackerItemClicked,
  setVehicleTabClicked,
  setStatusValue,
  setDeviceId,
  setClickedDate,
  setBeaconMac
}) => {
  const classes = useStyles();

  let todayDate = new Date();
  todayDate = todayDate.toLocaleDateString();
  todayDate = todayDate.split('/').reverse().join('-');

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setSearchInput(e.target.value);
    setFilter({ ...filter, searchInput: e.target.value });
    if (inputValue === null || inputValue.trim('') === '') {
      setVehicleTabClicked('now');
    }
    setTrackerItemClicked(false);
    setStatusValue(0);
    setDeviceId(null);
    setClickedDate(todayDate);
    setBeaconMac(null);
  };

  const handleSearch = () => {};

  const handlePreviousState = () => {
    setFilter({ ...filter, legendColor: 'All', searchInput: '', trackerState: 'All' });
    setTrackerItemClicked(false);
    setSearchInput('');
    setVehicleTabClicked('now');
    setStatusValue(0);
    setDeviceId(null);
    setClickedDate(todayDate);
    setBeaconMac(null);
  };

  return (
    <div className={classes.searchWrapper}>
      {searchInput.length > 1 && trackerItemClicked && (
        <div onClick={handlePreviousState} className={classes.backIcon}>
          <KeyboardBackspaceIcon />
        </div>
      )}
      <TextField
        required={true}
        id="search"
        name="search"
        type="text"
        value={searchInput}
        onChange={handleChange}
        placeholder="Search asset"
        InputProps={{ disableUnderline: true }}
        className={classes.textField}
      />
      <div onClick={handleSearch} className={classes.searchDiv}>
        <SearchIcon />
      </div>
    </div>
  );
};

export default Search;

Search.propTypes = {
  input: PropTypes.string,
  setFilter: PropTypes.func,
  filter: PropTypes.shape({
    legendColor: PropTypes.string,
    searchInput: PropTypes.string
  }),
  setSearchInput: PropTypes.func,
  searchInput: PropTypes.string,
  trackerItemClicked: PropTypes.bool,
  setTrackerItemClicked: PropTypes.func,
  setVehicleTabClicked: PropTypes.func,
  setStatusValue: PropTypes.func,
  setDeviceId: PropTypes.func,
  setClickedDate: PropTypes.func,
  setBeaconMac: PropTypes.func
};
