import React from 'react';
import PropTypes from 'prop-types';

import { Marker, Popup, Tooltip } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import L from 'leaflet';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { isLessThanAWeek, withInTenMinutes } from '../helper/helper';
import { getMarkerIcon } from './MarkerIcon';
import ReactDOMServer from 'react-dom/server';
import NavigationIcon from '@material-ui/icons/Navigation';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import LocationOnIcon from '@material-ui/icons/LocationOn';

// Create a rotating marker based on direction
const getRotatingMarkerIcon = (mapIcon, color) => {
  if (mapIcon === 'bluetooth') {
    const iconHTML = ReactDOMServer.renderToString(
      <BluetoothIcon style={{ fontSize: 30, color: color, strokeWidth: 1, stroke: color }} />
    );

    return L.divIcon({
      className: 'rotating-marker-icon',
      html: iconHTML,
      iconSize: [30, 30], // Adjust size as needed
      iconAnchor: [15, 15] // Center the icon
    });
  }
  if (mapIcon === 'vehicle') {
    const iconHTML = ReactDOMServer.renderToString(
      <DriveEtaIcon style={{ fontSize: 30, color: color, strokeWidth: 1, stroke: color }} />
    );

    return L.divIcon({
      className: 'rotating-marker-icon',
      html: iconHTML,
      iconSize: [30, 30], // Adjust size as needed
      iconAnchor: [15, 15] // Center the icon
    });
  }
  if (mapIcon === 'location') {
    const iconHTML = ReactDOMServer.renderToString(
      <LocationOnIcon style={{ fontSize: 30, color: color, strokeWidth: 1, stroke: color }} />
    );

    return L.divIcon({
      className: 'rotating-marker-icon',
      html: iconHTML,
      iconSize: [30, 30], // Adjust size as needed
      iconAnchor: [15, 15] // Center the icon
    });
  }
  if (mapIcon === 'navigation') {
    const iconHTML = ReactDOMServer.renderToString(
      <NavigationIcon style={{ fontSize: 30, color: color, strokeWidth: 1, stroke: color }} />
    );

    return L.divIcon({
      className: 'rotating-marker-icon',
      html: iconHTML,
      iconSize: [30, 30], // Adjust size as needed
      iconAnchor: [15, 15] // Center the icon
    });
  }
};

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const MapVehicleItem = ({ vehicleTrackerItem, handleClickedMapItem }) => {
  const latitude = -37.79869;
  const longitude = 144.89608;

  return (
    <Marker
      key={vehicleTrackerItem.deviceName}
      position={[vehicleTrackerItem.lat || latitude, vehicleTrackerItem.long || longitude]}
      icon={determineMarkerIcon(vehicleTrackerItem)}
      eventHandlers={{
        click: () =>
          handleClickedMapItem(vehicleTrackerItem.deviceName, vehicleTrackerItem.deviceId, vehicleTrackerItem.beaconMac)
      }}
    >
      <Popup>
        {vehicleTrackerItem.deviceName} <br />
      </Popup>
      <Tooltip direction="right" offset={[25, 20]} opacity={1} permanent>
        <span>{vehicleTrackerItem.deviceName}</span>
      </Tooltip>
    </Marker>
  );
};

export default MapVehicleItem;

MapVehicleItem.propTypes = {
  vehicleTrackerItem: PropTypes.shape({
    lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    long: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    deviceName: PropTypes.string,
    timestamp: PropTypes.number,
    positionDirection: PropTypes.any,
    engineIgnitionStatus: PropTypes.bool,
    mapIcon: PropTypes.string,
    beaconMac: PropTypes.any,
    deviceId: PropTypes.any
  }),
  handleClickedMapItem: PropTypes.func
};

const determineMarkerIcon = (vehicleTrackerItem) => {
  const { timestamp, mapIcon } = vehicleTrackerItem;

  if (withInTenMinutes(timestamp)) {
    return mapIcon ? getRotatingMarkerIcon(mapIcon, 'green') : getMarkerIcon('green');
  }
  if (isLessThanAWeek(timestamp)) {
    return mapIcon ? getRotatingMarkerIcon(mapIcon, 'orange') : getMarkerIcon('orange');
  }
  return mapIcon ? getRotatingMarkerIcon(mapIcon, 'red') : getMarkerIcon('red');
};
