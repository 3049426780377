import { useEffect, useState } from 'react';
import {
  getFormattedAssetWithTokenMac,
  getFormattedTrackerRecords,
  isLessThanAWeek,
  withInTenMinutes
} from '../helper/helper';

import { socket } from '../../../socket';
import assetService from '../../../services/AssetService';

//hook to show the assets in maps: MapComponent
export default function useFetchVehicleTrackerRecords(filter) {
  const [assetTrackerRecords, setAssetTrackerRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVehicleTrackerRecords = async () => {
      setLoading(true);
      try {
        //asset records with tokenMac
        const assetResponse = await assetService.getAssetWithTokenMac();
        const assetWithTokenMac = assetResponse?.data?.data;
        const assetFormattedRecords = getFormattedAssetWithTokenMac(assetWithTokenMac);

        // Apply filters
        const filteredRecords = assetFormattedRecords.filter((record) => {
          if (!record.timestamp) return false;

          if (filter.legendColor === 'All') return true;
          if (filter.legendColor === 'Green' && withInTenMinutes(record?.timestamp)) return true;
          if (filter.legendColor === 'Yellow' && isLessThanAWeek(record?.timestamp)) return true;
          if (
            filter.legendColor === 'Red' &&
            !withInTenMinutes(record?.timestamp) &&
            !isLessThanAWeek(record?.timestamp)
          )
            return true;

          return false;
        });

        setAssetTrackerRecords(filteredRecords);
      } catch (error) {
        setError('Failed to fetch vehicle tracker records');
        // eslint-disable-next-line no-console, no-undef
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicleTrackerRecords();

    const handleNewTrackerRecord = (newTrackerRecord) => {
      const formattedRecord = getFormattedTrackerRecords([newTrackerRecord])[0];

      // Update state with the new or updated record
      setAssetTrackerRecords((prevRecords) => {
        const recordExists = prevRecords.some((record) => record.deviceId === formattedRecord.deviceId);

        if (recordExists) {
          // Update the existing record
          return prevRecords.map((record) => (record.deviceId === formattedRecord.deviceId ? formattedRecord : record));
        }

        // Add the new record
        return [formattedRecord, ...prevRecords];
      });
    };

    socket.on('newVehicleTrackerRecord', handleNewTrackerRecord);

    // Cleanup on unmount
    return () => {
      socket.off('newVehicleTrackerRecord', handleNewTrackerRecord);
    };
  }, [filter.legendColor]);

  return { assetTrackerRecords, loading, error, setAssetTrackerRecords };
}
