import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';

const AssetMapPagination = ({
  assetMapPage,
  assetMapRowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  assetTrackerRecordsFromDb
}) => {
  return (
    <TablePagination
      rowsPerPageOptions={[10, 25]}
      component="div"
      count={assetTrackerRecordsFromDb.length}
      page={assetMapPage}
      onPageChange={handleChangePage}
      rowsPerPage={assetMapRowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default AssetMapPagination;

AssetMapPagination.propTypes = {
  assetMapPage: PropTypes.number,
  assetMapRowsPerPage: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  assetTrackerRecordsFromDb: PropTypes.any
};
